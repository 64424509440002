import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Reports from "./pages/Reports";
import FAQ from "./pages/FAQ";
import Login from "./pages/Login";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import MyTickets from "./pages/MyTickets";
import MyTeamTickets from "./pages/MyTeamTickets";
import axios from "axios";
import NotFound from "./pages/NotFound";
import Report2 from "./pages/Report2";
import Report3 from "./pages/Report3";
import Dashboard from "./pages/Dashboard";
import MessageSound from "./Sounds/mixkit-positive-notification-951.wav";
import CreatedByMe from "./pages/CreatedByMe";
import Settings from "./pages/Settings";
import WorkflowPanel from "./pages/WorkflowPanel";
import ReportWorkflow from "./pages/ReportWorkflow";
import ReportUserAganistWorkflows from "./pages/ReportUserAganistWorkflows";
import ClientSubmitForm from "./pages/ClientSubmitForm";
import DragAndDrop from "./pages/DragAndDrop";

function App() {
  let URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");
  const sound = new Audio(MessageSound);

  const [myTicAcc, setMyTicAcc] = useState("false");
  const [dashboardAcc, setDashboardAcc] = useState("false");
  const [myGroupTicAcc, setMyGroupTicAcc] = useState("false");
  const [allTicAcc, setAllTicAcc] = useState("false");
  const [reportAdvanced, setReportAdvanced] = useState("false");
  const [reportPerUser, setReportPerUser] = useState("false");
  const [reportPerCategory, setReportPerCategory] = useState("false");
  const [workflowReport, setWorkflowReport] = useState("false");
  const [userWorkflowReport, setUserWorkflowReport] = useState("false");
  const [dragAndDrop, setDragAndDrop] = useState("false");

  const [faq, setFaq] = useState("false");
  const [createdByMeTickets, setCreatedByMeTickets] = useState("false");
  const [settings, setSettings] = useState("false");

  const [myGroupTicketCount, setMyGroupTicketCount] = useState();

  const [user, setUser] = useState(true);
  const [propsUsername, setPropsUsername] = useState(true);
  const [nameForDisplay, setNameForDisplay] = useState([]);
  const [ticketAddPermission, setTicketAddPermission] = useState();
  const [notificationActivator, setNotificationActivator] = useState(false);

  const [sideBarReport, setSideBarReport] = useState(false);

  // ? Notifications

  const [unreadNot, setUnreadNot] = useState("");
  const [notificationRefresh, setNotificationRefresh] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(6);
  const [notificationIntervalTime, setNotificationIntervalTime] =
    useState(300000);

  const refresh = localStorage.getItem("refresh");

  const sendRefresh = {
    refresh: localStorage.getItem("refresh"),
  };

  const getMyUser = (token) => {
    axios
      .get(`${URL}/api/auth/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setNameForDisplay(res.data.data);
        setPropsUsername(res.data.data.username);
      })
      .catch(() => {
        setUser(false);
      });
  };

  const getSideBar = () => {
    const mapping = {
      DASHBOARD: setDashboardAcc,
      TICKET_ALL: setAllTicAcc,
      TICKET_MINE: setMyTicAcc,
      TICKET_MY_GROUP: setMyGroupTicAcc,
      REPORT_ADVANCED: setReportAdvanced,
      REPORT_PER_USER: setReportPerUser,
      REPORT_PER_CATEGORY: setReportPerCategory,
      REPORT_PER_WORKFLOW: setWorkflowReport,
      REPORT_PER_USER_AGAINST_WORKFLOWS: setUserWorkflowReport,
      FAQ: setFaq,
      CREATED_BY_ME: setCreatedByMeTickets,
      SETTINGS: setSettings,
      DRAG_AND_DROP: setDragAndDrop,
    };
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.data;
        Object.values(mapping).forEach((setState) => setState("false"));
        data.forEach((item) => {
          const setState = mapping[item.label];
          if (setState) {
            setState("");
          }
        });
      })
      .catch(() => {});
  };

  const func = () => {
    if (token != null && refresh != null) {
      getMyUser(token);
      getSideBar();
      axios
        .post(`${URL}/api/token/refresh/`, sendRefresh)
        .then((res) => {
          setUser(true);
          jwt_decode(token);
          localStorage.setItem("access", res.data.data.access);
          getUserAddPermission();
        })
        .catch((error) => {
          if (
            error?.response?.request.status === 401 ||
            error?.response?.request.status === 400
          ) {
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            localStorage.removeItem("username");
            setUser(false);
          }
        });
    } else {
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      setUser(false);
    }
  };

  const getUserAddPermission = () => {
    axios
      .get(`${URL}/api/auth/me/permissions/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTicketAddPermission(res.data.data.can_add_ticket);
      })
      .catch(() => {});
  };

  useEffect(() => {
    func();
  }, [user]);

  useEffect(() => {
    getUnreadNotificationsTopbar();
    getNotificationData(notificationCount);
  }, [notificationRefresh, user]);

  const getUnreadNotifications = () => {
    if (localStorage.getItem("notifications") === "true") {
      axios
        .get(`${URL}/api/notification/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            is_read: false,
            page_size: 100,
          },
        })
        .then((res) => {
          setNotificationRefresh(!notificationRefresh);
          if (res.data.data.count !== 0) {
            try {
              sound.play();
            } catch (err) {}
          }
        })
        .catch(() => {});
    }
  };

  const getUnreadNotificationsTopbar = () => {
    if (localStorage.getItem("notifications") === "true") {
      axios
        .get(`${URL}/api/notification/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            is_read: false,
            page_size: 100,
          },
        })
        .then((res) => {
          res.data.data.count !== 0 ? setUnreadNot(true) : setUnreadNot(false);
        })
        .catch(() => {});
    }
  };

  const getNotificationData = (props) => {
    if (localStorage.getItem("notifications") === "true") {
      axios
        .get(`${URL}/api/notification/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page_size: props,
          },
        })
        .then((res) => {
          setNotificationData(res.data.data.results);
        })
        .catch(() => {});
    }
  };

  const notificationInterval = () => {
    if (localStorage.getItem("notifications") === "true") {
      axios
        .get(`${URL}/api/notification/polling-interval/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setNotificationIntervalTime(res.data.data.interval * 1000);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    notificationInterval();
    axios(`${URL}/api/tickets/my_category/?status=0`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setMyGroupTicketCount(res?.data?.data?.count);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotificationData();
      getUnreadNotificationsTopbar();
      if (notificationActivator === true) {
        getUnreadNotifications();
      }
    }, notificationIntervalTime);
    return () => clearInterval(intervalId);
  }, [notificationActivator, notificationIntervalTime]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios(`${URL}/api/tickets/my_category/?status=0`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setMyGroupTicketCount(res?.data?.data?.count);
        })
        .catch(() => {});
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      onClick={() => {
        if (notificationActivator === false) {
          setNotificationActivator(true);
        }
      }}
      className="App"
    >
      <Routes>
        <Route
          path="/panel"
          element={
            user ? <WorkflowPanel setUser={setUser} /> : <Navigate to="/" />
          }
        />
        <Route
          path="/clientSubmitForm"
          element={
            user ? <ClientSubmitForm setUser={setUser} /> : <Navigate to="/" />
          }
        />
        <Route
          path="/"
          element={
            user ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login setUser={setUser} permission={setTicketAddPermission} />
            )
          }
        />
        <Route
          path="/myTickets"
          element={
            user ? (
              <MyTickets
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                ticketAddPermission={ticketAddPermission}
                // ? Sidebar
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/settings"
          element={
            user ? (
              <Settings
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                ticketAddPermission={ticketAddPermission}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/createdByMe"
          element={
            user ? (
              <CreatedByMe
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                ticketAddPermission={ticketAddPermission}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            user ? (
              <Dashboard
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                ticketAddPermission={ticketAddPermission}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                setNotificationRefresh={setNotificationRefresh}
                notificationData={notificationData}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/myTeamTickets"
          element={
            user ? (
              <MyTeamTickets
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                ticketAddPermission={ticketAddPermission}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                setMyGroupTicketCount={setMyGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/home"
          element={
            user ? (
              <Home
                setUser={setUser}
                ticketAddPermission={ticketAddPermission}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/reports3"
          element={
            user ? (
              <Report3
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/reports"
          element={
            user ? (
              <Reports
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/reports2"
          element={
            user ? (
              <Report2
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/dragAndDrop"
          element={
            user ? (
              <DragAndDrop
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/reportWorkflow"
          element={
            user ? (
              <ReportWorkflow
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/reportUserAganistWorkflows"
          element={
            user ? (
              <ReportUserAganistWorkflows
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/FAQ"
          element={
            user ? (
              <FAQ
                setUser={setUser}
                propsUsername={propsUsername}
                nameForDisplay={nameForDisplay}
                // ? Sidebar
                settings={settings}
                faq={faq}
                reportPerCategory={reportPerCategory}
                workflowReport={workflowReport}
                userWorkflowReport={userWorkflowReport}
                dragAndDrop={dragAndDrop}
                reportPerUser={reportPerUser}
                reportAdvanced={reportAdvanced}
                allTicAcc={allTicAcc}
                myGroupTicAcc={myGroupTicAcc}
                dashboardAcc={dashboardAcc}
                myTicAcc={myTicAcc}
                setSideBarReport={setSideBarReport}
                sideBarReport={sideBarReport}
                createdByMeTickets={createdByMeTickets}
                myGroupTicketCount={myGroupTicketCount}
                // ? Notifications
                unreadNot={unreadNot}
                notificationRefresh={notificationRefresh}
                notificationData={notificationData}
                setNotificationRefresh={setNotificationRefresh}
                setNotificationCount={setNotificationCount}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
