// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info,
.warning,
.danger {
  display: flex;
  border-radius: 8px;
  padding: 10px 20px;
}
.info .announcmentR,
.warning .announcmentR,
.danger .announcmentR {
  width: 99%;
  padding-left: 12px;
}
.info .announcmentR p,
.warning .announcmentR p,
.danger .announcmentR p {
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.info .announcmentR span,
.warning .announcmentR span,
.danger .announcmentR span {
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #71717a;
}

.info {
  background-color: #eff6ff;
}

.warning {
  background-color: #fefce8;
}

.danger {
  background-color: #fef2f2;
}
.danger .announcmentR p {
  color: #dc2626;
}
.danger .announcmentR span {
  color: #dc2626;
}`, "",{"version":3,"sources":["webpack://./src/style/component/announcmentCard.scss"],"names":[],"mappings":"AAAA;;;EAGE,aAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAAE;;;EACE,UAAA;EACA,kBAAA;AAIJ;AAHI;;;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAON;AALI;;;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AASN;;AALA;EACE,yBAAA;AAQF;;AANA;EACE,yBAAA;AASF;;AAPA;EACE,yBAAA;AAUF;AARI;EACE,cAAA;AAUN;AARI;EACE,cAAA;AAUN","sourcesContent":[".info,\n.warning,\n.danger {\n  display: flex;\n  border-radius: 8px;\n  padding: 10px 20px;\n  .announcmentR {\n    width: 99%;\n    padding-left: 12px;\n    p {\n      font-family: inter;\n      font-weight: 500;\n      font-size: 14px;\n      line-height: 20px;\n    }\n    span {\n      font-family: inter;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 20px;\n      color: #71717a;\n    }\n  }\n}\n.info {\n  background-color: #eff6ff;\n}\n.warning {\n  background-color: #fefce8;\n}\n.danger {\n  background-color: #fef2f2;\n  .announcmentR {\n    p {\n      color: #dc2626;\n    }\n    span {\n      color: #dc2626;\n    }\n  }\n}\n.announcmentIcon{\n  // width: 50px;\n  // margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
