import React, { useState } from "react";
import Linkify from "./Linkify";
import { EditComment, ForwardIcon, MiniClose, Smile } from "../svg";
import { Textarea } from "../components/ui/textarea";
import axios from "axios";
import { DialogCloseButton } from "./Alert";

const Comment = (props) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const [editComment, setEditComment] = useState(false);
  const [commentData, setCommentData] = useState(props.text);

  const [smileToggle, setSmileToggle] = useState(false);

  const editCommentFunc = (e) => {
    axios
      .patch(
        `${URL}/api/tickets/${props.ticketID}/comments/${props.commentID}/update/`,
        {
          text: commentData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        props.getHistories();
        getComments();
        setEditComment(!editComment);
        props.setVisibilityForEditComment("");
      })
      .catch((res) => {
        setEditComment(!editComment);
        props.setVisibilityForEditComment("");
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.non_field_errors[0],
          success: res.response.data.success,
        });
      });
  };

  const sendSmileFunc = (props) => {
    setCommentData(commentData + `${props}`);
    setSmileToggle(!smileToggle);
  };

  const getComments = () => {
    axios
      .get(`${URL}/api/tickets/${props.ticketID}/comments/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(() => {})
      .then((res) => {
        props.setComments(res?.data?.data);
      });
  };

  return (
    <>
      {error.open ? (
        <DialogCloseButton setError={setError} error={error} />
      ) : null}

      <div>
        <div className="detailsContentRWhoseDate">
          <div className="detailsContentRWhose">
            <img
              className="commentIMG"
              src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
              alt=""
            />
            <span>{props.name}</span>
          </div>
          <span>{props.commentDate}</span>
          <div
            onClick={() => {
              setEditComment(!editComment);
              props.setVisibilityForEditComment("false");
            }}
            style={{
              display: editComment === true ? "none" : "",
            }}
            className="editCommentButton"
          >
            <EditComment />
          </div>
          <div
            onClick={() => {
              setEditComment(!editComment);
              props.setVisibilityForEditComment("");
            }}
            style={{
              padding: "8px",
              display: editComment === false ? "none" : "",
            }}
            className="editCommentButton"
          >
            <MiniClose />
          </div>
        </div>
        <div className="detailsContentRComment">
          {editComment === false ? (
            <span className="">
              <Linkify text={props.text} />
            </span>
          ) : (
            <div className="relative">
              <Textarea
                className="ticketCommentTextArea"
                onChange={(e) => {
                  setCommentData(e.target.value);
                }}
                value={commentData}
                type="text"
              />
              <div
                onClick={() => setSmileToggle(!smileToggle)}
                className="smileToggleBtn"
              >
                <Smile />
              </div>
              <div
                style={{
                  display: smileToggle === false ? "none" : "",
                }}
                className="smilesForComment"
              >
                <div className="smileTriangleForComment"></div>
                <div className="smileItemParentForComment">
                  <span
                    onClick={() => {
                      sendSmileFunc("👍🏻");
                    }}
                    className="smileItem"
                  >
                    👍🏻
                  </span>
                  <span
                    onClick={() => {
                      sendSmileFunc("✅");
                    }}
                    className="smileItem"
                  >
                    ✅
                  </span>
                  <span
                    onClick={() => {
                      sendSmileFunc("❌");
                    }}
                    className="smileItem"
                  >
                    ❌
                  </span>
                  <span
                    onClick={() => {
                      sendSmileFunc("🤝");
                    }}
                    className="smileItem"
                  >
                    🤝
                  </span>
                  <span
                    onClick={() => {
                      sendSmileFunc("😂");
                    }}
                    className="smileItem"
                  >
                    😂
                  </span>
                  <span
                    onClick={() => {
                      sendSmileFunc("👌🏻");
                    }}
                    className="smileItem"
                  >
                    👌🏻
                  </span>
                </div>
              </div>
              <button
                // disabled={!createCommentPerm}
                onClick={() => {
                  editCommentFunc();
                }}
                className="editCommentSendButton"
              >
                Göndər{"  "}
                <ForwardIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Comment;
