import React from "react";
import styled from "styled-components";

import "../style/component/dragAndDrop.scss";
import { Droppable } from "react-beautiful-dnd";
import DragAndDropCard from "./DragAndDropCard";

const Container = styled.div`
  border-radius: 2.5px;
  width: 292px;
  height: 900px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Title = styled.h3`
  padding: 8px 8px 8px 3px;
`;

const TaskList = styled.div`
  padding: 3px;
  transistion: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`;

export default function Column({ title, tasks, id }) {
  return (
    <Container className="column">
      <Title
        style={{
          color: "#71717A",
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "20px",
        }}
      >
        {title}
      </Title>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {tasks.map((task, index) => (
              <DragAndDropCard key={index} index={index} task={task} />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </Container>
  );
}
