import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="notFoundTT">
      <div className="notFound">
        <h2>404 - Page not found</h2>
        <p>Keçid etmək istədiyiniz səhifə əlçatan deyil.</p>
        <span onClick={() => navigate("/dashboard")}>Dashboard`a keçid et</span>
      </div>
    </div>
  );
};

export default NotFound;
