import React, { useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  DashboardI,
  DocumentText,
  MenuBar,
  MessageQuestion,
  ReportsDotI,
  Settings,
  TaskSquare,
  WhatsAppIcon,
} from "../svg";
import { Link } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";

const Header = ({
  dashboard,
  propsMy,
  propsSettings,
  propsTeam,
  propsAll,
  propsReports,
  propsFaq,
  propsReports2,
  propsReports3,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  createdByMeTickets,
  sideBarReport,
  setSideBarReport,
  cratedByMeSide,
  settings,
  myGroupTicketCount,
  workflowReport,
  workflowReportClass,
  userWorkflowReport,
  userWorkflowReportClass,
  dragAndDrop,
  propsDragAndDrop,
}) => {
  const [numberWp, setNumberWp] = useState({
    number: "",
  });

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setNumberWp((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <div className="sideBar">
      <div className="sideBarLogo">
        <div className="responsiveMenuBar">
          <Sheet>
            <SheetTrigger asChild>
              <MenuBar />
            </SheetTrigger>
            <SheetContent className="w-[292px] z-50" side={"left"}>
              <SheetHeader>
                <img
                  className="menuBarIMG"
                  src={require("../images/sideBarLogo.png")}
                  alt=""
                />
              </SheetHeader>
              <ul className="sideBarUlResponsiveMenuBar">
                <Link className={dashboard} to={"/dashboard"}>
                  <li className={dashboardAcc}>
                    <span>
                      <DashboardI />
                    </span>
                    Dashboard
                  </li>
                </Link>
                <Link className={propsMy} to={"/myTickets"}>
                  <li className={myTicAcc}>
                    <span>
                      <TaskSquare />
                    </span>
                    Mənim Tiketlərim
                  </li>
                </Link>
                <Link className={cratedByMeSide} to={"/createdByMe"}>
                  <li className={createdByMeTickets}>
                    <span>
                      <TaskSquare />
                    </span>
                    Yaratdığım tiketlər
                  </li>
                </Link>
                <Link className={propsTeam} to={"/myTeamTickets"}>
                  <li className={myGroupTicAcc}>
                    <span>
                      <TaskSquare />
                    </span>
                    Qrupumun Tiketləri
                    <span
                      className={
                        myGroupTicketCount > 0 ? "groupTicketsCount" : "false"
                      }
                    >
                      {myGroupTicketCount}
                    </span>
                  </li>
                </Link>
                <Link className={propsAll} to={"/home"}>
                  <li className={allTicAcc}>
                    <span>
                      <TaskSquare />
                    </span>
                    Bütün Tiketlər
                  </li>
                </Link>
                <Link className={propsDragAndDrop} to={"/dragAndDrop"}>
                  <li className={dragAndDrop}>
                    <span>
                      <TaskSquare />
                    </span>
                    Drag and drop
                  </li>
                </Link>
                <Link
                  className="sideBarReportParentClass"
                  onClick={() => setSideBarReport(!sideBarReport)}
                >
                  <li>
                    <span>
                      <DocumentText />
                    </span>
                    Reportlar
                  </li>
                  <span className="sideBarReportArrowClass">
                    {sideBarReport === true ? <ArrowUp /> : <ArrowDown />}
                  </span>
                </Link>
                <div className={sideBarReport === true ? "" : "false"}>
                  <Link className={propsReports} to={"/reports"}>
                    <li className={reportAdvanced}>
                      <span>
                        <ReportsDotI />
                      </span>
                      Ətraflı Report
                    </li>
                  </Link>
                  <Link className={propsReports2} to={"/reports2"}>
                    <li className={reportPerUser}>
                      <span>
                        <ReportsDotI />
                      </span>
                      İstifadəçi Report
                    </li>
                  </Link>
                  <Link className={propsReports3} to={"/reports3"}>
                    <li className={reportPerCategory}>
                      <span>
                        <ReportsDotI />
                      </span>
                      Kateqoriya Report
                    </li>
                  </Link>
                  <Link className={workflowReportClass} to={"/reportWorkflow"}>
                    <li className={workflowReport}>
                      <span>
                        <ReportsDotI />
                      </span>
                      Workflow Report
                    </li>
                  </Link>
                  <Link
                    className={userWorkflowReportClass}
                    to={"/reportUserAganistWorkflows"}
                  >
                    <li className={userWorkflowReport}>
                      <span>
                        <ReportsDotI />
                      </span>
                      User Workflow Report
                    </li>
                  </Link>
                </div>

                <Link className={propsFaq} to={"/FAQ"}>
                  <li className={faq}>
                    <span>
                      <MessageQuestion />
                    </span>
                    FAQ
                  </li>
                </Link>

                <Link className={propsSettings} to={"/settings"}>
                  <li className={settings}>
                    <span>
                      <Settings />
                    </span>
                    Tənzimləmələr
                  </li>
                </Link>

                <Dialog>
                  <DialogTrigger asChild>
                    <Link>
                      <li>
                        <span>
                          <WhatsAppIcon />
                        </span>
                        WhatsApp
                      </li>
                    </Link>
                  </DialogTrigger>
                  <DialogContent className="changePassDialog">
                    <DialogHeader>
                      <DialogTitle>Nömrəni qeyd edin</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4">
                      <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                          Nömrə
                        </Label>
                        <Input
                          id="name"
                          onChange={handleChange}
                          name="number"
                          type="number"
                          placeholder="**********"
                          className="col-span-3"
                        />
                      </div>
                    </div>
                    <DialogFooter>
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        to={`https://api.whatsapp.com/send?phone=${
                          numberWp.number[0] === "0"
                            ? `994${numberWp.number?.slice(1)}`
                            : numberWp.number[0] === "+"
                            ? numberWp.number?.slice(1)
                            : // : numberWp[0,3] !== "994"
                              // ? `994${numberWp.number}`
                              `${numberWp.number}`
                        }`}
                      >
                        <Button>WhatsApp'a keçid edin</Button>
                      </Link>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </ul>
            </SheetContent>
          </Sheet>
        </div>
        <div className="sideBarLogoImg"></div>
      </div>

      <ul className="sideBarUl">
        <Link className={dashboard} to={"/dashboard"}>
          <li className={dashboardAcc}>
            <span>
              <DashboardI />
            </span>
            Dashboard
          </li>
        </Link>
        <Link className={propsMy} to={"/myTickets"}>
          <li className={myTicAcc}>
            <span>
              <TaskSquare />
            </span>
            Mənim Tiketlərim
          </li>
        </Link>
        <Link className={cratedByMeSide} to={"/createdByMe"}>
          <li className={createdByMeTickets}>
            <span>
              <TaskSquare />
            </span>
            Yaratdığım tiketlər
          </li>
        </Link>
        <Link className={propsTeam} to={"/myTeamTickets"}>
          <li className={myGroupTicAcc}>
            <span>
              <TaskSquare />
            </span>
            Qrupumun Tiketləri
            <span
              className={myGroupTicketCount > 0 ? "groupTicketsCount" : "false"}
            >
              {myGroupTicketCount}
            </span>
          </li>
        </Link>
        <Link className={propsAll} to={"/home"}>
          <li className={allTicAcc}>
            <span>
              <TaskSquare />
            </span>
            Bütün Tiketlər
          </li>
        </Link>
        <Link className={propsDragAndDrop} to={"/dragAndDrop"}>
          <li className={dragAndDrop}>
            <span>
              <TaskSquare />
            </span>
            Drag and drop
          </li>
        </Link>
        <Link
          className="sideBarReportParentClass"
          onClick={() => setSideBarReport(!sideBarReport)}
        >
          <li>
            <span>
              <DocumentText />
            </span>
            Reportlar
          </li>
          <span className="sideBarReportArrowClass">
            {sideBarReport === true ? <ArrowUp /> : <ArrowDown />}
          </span>
        </Link>
        <div className={sideBarReport === true ? "" : "false"}>
          <Link className={propsReports} to={"/reports"}>
            <li className={reportAdvanced}>
              <span>
                <ReportsDotI />
              </span>
              Ətraflı Report
            </li>
          </Link>
          <Link className={propsReports2} to={"/reports2"}>
            <li className={reportPerUser}>
              <span>
                <ReportsDotI />
              </span>
              İstifadəçi Report
            </li>
          </Link>
          <Link className={propsReports3} to={"/reports3"}>
            <li className={reportPerCategory}>
              <span>
                <ReportsDotI />
              </span>
              Kateqoriya Report
            </li>
          </Link>
          <Link className={workflowReportClass} to={"/reportWorkflow"}>
            <li className={workflowReport}>
              <span>
                <ReportsDotI />
              </span>
              Workflow Report
            </li>
          </Link>
          <Link
            className={userWorkflowReportClass}
            to={"/reportUserAganistWorkflows"}
          >
            <li className={userWorkflowReport}>
              <span>
                <ReportsDotI />
              </span>
              User Workflow Report
            </li>
          </Link>
        </div>

        <Link className={propsFaq} to={"/FAQ"}>
          <li className={faq}>
            <span>
              <MessageQuestion />
            </span>
            FAQ
          </li>
        </Link>

        <Link className={propsSettings} to={"/settings"}>
          <li className={settings}>
            <span>
              <Settings />
            </span>
            Tənzimləmələr
          </li>
        </Link>

        <Dialog>
          <DialogTrigger asChild>
            <Link>
              <li>
                <span>
                  <WhatsAppIcon />
                </span>
                WhatsApp
              </li>
            </Link>
          </DialogTrigger>
          <DialogContent className="changePassDialog">
            <DialogHeader>
              <DialogTitle>Nömrəni qeyd edin</DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nömrə
                </Label>
                <Input
                  id="name"
                  onChange={handleChange}
                  name="number"
                  type="number"
                  placeholder="**********"
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Link
                target="_blank"
                rel="noreferrer"
                to={`https://api.whatsapp.com/send?phone=${
                  numberWp.number[0] === "0"
                    ? `994${numberWp.number?.slice(1)}`
                    : numberWp.number[0] === "+"
                    ? numberWp.number?.slice(1)
                    : // : numberWp[0,3] !== "994"
                      // ? `994${numberWp.number}`
                      `${numberWp.number}`
                }`}
              >
                <Button>WhatsApp'a keçid edin</Button>
              </Link>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </ul>
    </div>
  );
};

export default Header;
