import React, { useEffect, useRef, useState } from "react";
import { DialogCloseButton } from "../component/Alert";

// ? UI Components

import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";
import { Label } from "../components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";

// ? SVG'S

import {
  BlueCircle,
  GreenCircle,
  BlackCircle,
  EditCategory,
  ForwardIcon,
  RightArrowWithLine,
  MiniClose,
  TicketLabelCircle,
  WhatsAppIconForTicket,
  Smile,
  EditComment,
} from "../svg";

// ? Components

import TaskHistoryChanger from "./TaskHistoryChanger";
import Comment from "./Comment";
import axios from "axios";
import Linkify from "./Linkify";
import { DialogClose } from "@radix-ui/react-dialog";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../components/ui/input";

const Ticket = (circle) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const [assignedID, setAssignedID] = useState();
  const [ticketData, setTicketData] = useState({
    text: "",
  });

  const [dataCategories, setDataCategories] = useState([]);
  const [activeFirst, setActiveFirst] = useState("active");
  const [activeSecond, setActiveSecond] = useState("nonactive");
  const [historyCommentChanger, setHistoryCommentChanger] = useState("");
  const [changeTaskHistory, setChangeTaskHistory] = useState("false");
  const [histories, setHistories] = useState([]);
  const [comments, setComments] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [avialableUsersToAssign, setAvialableUsersToAssign] = useState();
  const [ticketCategoryChanger, setTicketCategoryChanger] = useState("false");
  const [assignedOrNo, setAssignedOrNo] = useState("false");
  const [assignedOrNo2, setAssignedOrNo2] = useState("");
  const [username, setUsername] = useState("");
  const [stageData, setStageData] = useState("");
  const [postState, setPostState] = useState("");
  const [assignData, setAssignData] = useState("");
  const [assignState2, setAssignState2] = useState("");
  const [ticketGroupData, setTicketGroupData] = useState(circle.ownerGroup);
  const [categoryData, setCategoryData] = useState("");
  const [nonAsignee, setNonAsignee] = useState("");
  const [categoryClass, setCategoryClass] = useState("");
  const [circleColor, setCircleColor] = useState(circle?.state?.label);
  const [ifTicketAssigneeMe, setIfTicketAssigneeMe] = useState(
    circle.ifAssigneMePost
  );
  const [smileToggle, setSmileToggle] = useState(false);

  const [changeDetect, setChangeDetect] = useState(circle.stateRefresh);

  const [nextAvailableWorkflows, setNextAvailableWorkflows] = useState([]);
  const [workflowChanger, setWorkflowChanger] = useState("false");

  const [visibilityForEditComment, setVisibilityForEditComment] = useState("");
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  // ?Permissions

  const [changeCategoryPerm, setChangeCategoryPerm] = useState("false");
  // const [createCommentPerm, setCreateCommentPerm] = useState("true");
  const [getCommentPerm, setGetCommentPerm] = useState("");
  const [getHistoryPerm, setGetHistoryPerm] = useState("");
  const [changeStagePerm, setChangeStagePerm] = useState("false");

  const [canAssignTicketThemselves, setCanAssignTicketThemselves] =
    useState("false");
  const [canAssignTicketToOthers, setCanAssignTicketToOthers] =
    useState("false");
  const [canChangeTicketAssignee, setCanChangeTicketAssignee] =
    useState("false");
  const [canRemoveTicketFromThemselves, setCanRemoveTicketFromThemselves] =
    useState("false");
  const [canRemoveTicketAssignee, setCanRemoveTicketAssignee] =
    useState("false");

  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });
  const ref = useRef(null);

  const [fieldss, setFieldss] = useState(circle?.customFields);
  const [customFieldsState, setCustomFieldsState] = useState(
    circle?.customFields
  );

  const [requestTypeFields, setRequestTypeFields] = useState(
    circle?.requestTypeFields
  );

  const handleInputChange = (id, newValue) => {
    setSaveButtonDisabled(false);
    setFieldss((prevFields) =>
      prevFields.map((field) =>
        field.id === id ? { ...field, value: newValue } : field
      )
    );
    setChangedFields((prevChangedFields) => ({
      ...prevChangedFields,
      [id]: newValue,
    }));
  };

  const editTicketData = () => {
    const custom_field_definitions = unmatchedFields
      .filter((field) => formValues[field.id]) // Filter out fields with empty values
      .map((field) => ({
        field_definition: field.id,
        value: formValues[field.id],
        ticket: circle.id,
      }));
    const custom_field_values = fieldss
      .filter((field) => field.id in changedFields)
      .map(({ field_definition, ...field }) => field);
    axios
      .patch(
        `${URL}/api/tickets/${circle.id}/`,
        {
          description: prevTicketDescription?.description,
          custom_field_values,
          custom_field_definitions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((res) => {
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.error,
          success: res.response.data.success,
        });
      })
      .then((res) => {
        getHistories();
        setChangeDetect(!circle.stateRefresh);
        setSaveButtonDisabled(true);
        setEditTicketToggle(false);
        setCustomFieldsState(res?.data?.data?.fields);
        setRequestTypeFields(
          res.data.data.current_category?.request_type?.fields
        );
        setFieldss(res?.data?.data?.fields);
        setPrevTicketDescription({ description: res?.data?.data?.description });
      });
  };

  const [changedFields, setChangedFields] = useState({});
  const [editTicketToggle, setEditTicketToggle] = useState(false);
  const [prevTicketDescription, setPrevTicketDescription] = useState();

  const [unmatchedFields, setUnmatchedFields] = useState([]);
  const [formValues, setFormValues] = useState({});

  const handleInputChangeUnmatchedFields = (fieldId, value) => {
    setSaveButtonDisabled(false);
    setFormValues({
      ...formValues,
      [fieldId]: value,
    });
  };

  // ? CTRL+ENTER FUNC

  const shortcuts = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      postComment();
    }
  };

  // ?

  useEffect(() => {
    const findUnmatchedFields = () => {
      const unmatched = requestTypeFields?.filter(
        (requestTypeFields) =>
          !customFieldsState?.some(
            (customFieldsState) =>
              customFieldsState?.field_definition?.name === requestTypeFields?.name
          )
      );
      setUnmatchedFields(unmatched);
    };

    findUnmatchedFields();
  }, [requestTypeFields, customFieldsState]);

  // ! FUNCTIONS

  const sendSmileFunc = (props) => {
    setTicketData((prevState) => ({
      ...prevState,
      text: prevState.text + `${props}`,
    }));
    setSmileToggle(!smileToggle);
  };

  const handleUserToAssign = (id) => {
    axios
      .post(
        `${URL}/api/tickets/${circle.id}/change_assignee/${id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        getAvailableWorkflows();
        setAssignData(
          res?.data?.data?.assignee
            ? res?.data?.data?.assignee?.full_name.length === 0
              ? res?.data?.data?.assignee?.username
              : res?.data?.data?.assignee?.full_name
            : "İcraçı təyin edilməyib"
        );
        setAssignState2("false");
        res?.data?.data?.assignee?.username === localStorage.getItem("username")
          ? setIfTicketAssigneeMe("")
          : setIfTicketAssigneeMe("false");
        setError({
          open: true,
          title: "Uğurlu əməliyyat!",
          description: res?.data?.success_message,
          success: res?.data?.success,
        });
        getHistories();
        getPermissions();
        setRefresh(!refresh);
        setAssignedOrNo2("false");
        setAssignedOrNo("");
        setAssignedID("");
      })
      .catch((res) => {
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.error,
          success: res.response.data.success,
        });
      });
  };

  const getComments = () => {
    axios
      .get(`${URL}/api/tickets/${circle.id}/comments/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(() => {})
      .then((res) => {
        setComments(res?.data?.data);
      });
  };

  const getAvailableWorkflows = () => {
    axios
      .get(`${URL}/api/tickets/${circle.id}/next_available_workflows/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(() => {})
      .then((res) => {
        setNextAvailableWorkflows(res.data.data);
        if (res.data.data.length === 0) {
          setWorkflowChanger("false");
        } else {
          setWorkflowChanger("");
        }
      });
  };

  const postAvailableWorkflows = (workflowID) => {
    axios
      .post(
        `${URL}/api/tickets/${circle.id}/move_to_specific_workflow/${workflowID}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((res) => {
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.error,
          success: res.response.data.success,
        });
      })
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        // getAvailableWorkflows();
        setWorkflowChanger("");
        setIfTicketAssigneeMe("false");
        setCircleColor(res.data.data.current_stage.label);
        setStageData(res.data.data.current_stage?.label);
        setTicketGroupData(res?.data.data.owner_group.name);
        setPostState("false");
        if (res.data.data.assignee === null) {
          setAssignedOrNo2("");
          setAssignedOrNo("false");
        }
        setRefresh(!refresh);
        getHistories();
        setAssignedID("");
        if (res.data.data?.completed_at != null) {
          setCanChangeTicketAssignee("false");
          setCanAssignTicketThemselves("false");
        }
      })
      .catch(() => {});
  };

  const handleClickComment = () => {
    ref.current.value = "";
  };

  const postComment = () => {
    if (ticketData.text?.length !== 0) {
      axios
        .post(`${URL}/api/tickets/${circle.id}/comments/create/`, ticketData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((res) => {
          setError({
            open: true,
            title: "Uğursuz əməliyyat",
            description: res.response.data.data.error,
            success: res.response.data.success,
          });
        })
        .then((res) => {
          setChangeDetect(!circle.stateRefresh);
          setTicketData({
            text: "",
          });
          setError({
            open: true,
            title: "Uğurlu əməliyyat!",
            description: "Şərh uğurla əlavə olundu",
            success: res.data.success,
          });
          setRefresh(!refresh);
          getComments();
          getHistories();
          handleClickComment();
        });
    }
  };

  const handleChangeCategory = (e) => {
    axios
      .post(
        `${URL}/api/tickets/${circle.id}/change_category/${e}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        setError({
          open: true,
          title:
            res?.data.success === false
              ? "Uğursuz əməliyyat"
              : "Uğurlu əməliyyat!",
          description: res?.data.success_message,
          success: res?.data.success,
        });
        setStageData(res?.data.data.current_stage?.label);
        setPostState("false");
        setCategoryData(res?.data.data.current_category.name);
        setTicketGroupData(res?.data.data.owner_group.name);
        setCategoryClass("false");
        setAssignedOrNo("false");
        setAssignedOrNo2("");
        setTicketCategoryChanger("false");
        getHistories();
        getAvailableWorkflows();
        getPermissions();
        // circle.setStateRefresh(!circle.stateRefresh);
        setRefresh(!refresh);
        getAvialableUsersToAssign();
        setAssignedID("");
        setIfTicketAssigneeMe("false");
        setCustomFieldsState(res?.data?.data?.fields);
        setRequestTypeFields(
          res.data.data.current_category?.request_type?.fields
        );
      })
      .catch((res) => {
        categoryChangeSelectClose();
        if (res?.response?.status) {
          setError({
            open: true,
            title: "Uğursuz əməliyyat",
            description: res?.response?.data?.data?.error,
            success: res?.response?.data?.success,
          });
        }
      });
  };

  const postNextStage = () => {
    axios
      .post(
        `${URL}/api/tickets/${circle.id}/move_next_stage/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((res) => {
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.error,
          success: res.response.data.success,
        });
      })
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        getAvailableWorkflows();
        setCircleColor(res.data.data.current_stage.label);
        setStageData(res.data.data.current_stage?.label);
        setTicketGroupData(res?.data.data.owner_group.name);
        setPostState("false");
        setError({
          open: true,
          title: "Uğurlu əməliyyat!",
          description: res.data.success_message,
          success: res.data.success,
        });
        if (res.data.data.assignee === null) {
          setAssignedOrNo2("");
          setAssignedOrNo("false");
        }
        setRefresh(!refresh);
        getHistories();
        setAssignedID("");
        if (res.data.data?.completed_at != null) {
          setCanChangeTicketAssignee("false");
          setCanAssignTicketThemselves("false");
        }
      })
      .catch(() => {});
  };

  const testAssignedOrNo = () => {
    if (circle.assigned === "İcraçı təyin edilməyib") {
      setAssignedOrNo("false");
      setAssignedOrNo2("");
    } else {
      setAssignedOrNo("");
      setAssignedOrNo2("false");
    }
  };

  const getAvialableUsersToAssign = () => {
    axios(
      `${URL}/api/tickets/${circle.id}/available_users_to_assign/`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .catch(() => {})
      .then((res) => {
        setAvialableUsersToAssign(res?.data?.data);
      });
  };

  const setToMeFunc = () => {
    axios(`${URL}/api/tickets/${circle.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        if (res.data.data.assignee === null) {
          avialableUsersToAssign?.filter((item) => {
            if (item.id === username) {
              handleUserToAssign(item.id);
              setAssignedID(item.id);
            }
          });
        } else {
          setError({
            open: true,
            title: "Uğursuz əməliyyat",
            description: "Ticket başqa icraçı tərəfindən təyin olunub!",
            success: false,
          });
        }
      })
      .catch(() => {});
  };

  const getPermissions = () => {
    axios(
      `${URL}/api/tickets/${circle.id}/permissions/`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .catch(() => {})
      .then((res) => {
        // circle.createdBy === localStorage.getItem("username")
        //   ? setChangeCategoryPerm("")
        //   :
        res?.data?.data.can_change_ticket_category === false
          ? setChangeCategoryPerm("false")
          : setChangeCategoryPerm("");
        // --------------------------
        // setCreateCommentPerm(res.data.data.can_create_comment);
        // --------------------------
        // res.data.data.can_view_comment === false
        //   ? setGetCommentPerm("false")
        //   : setGetCommentPerm("");
        // --------------------------
        // res.data.data.can_view_history === false
        //   ? setGetHistoryPerm("false")
        //   : setGetHistoryPerm("");
        // --------------------------
        res?.data?.data.can_move_ticket_to_next_stage === false
          ? setChangeStagePerm("false")
          : setChangeStagePerm("");
        // --------------------------
        // --------------------------
        if (res?.data?.data.can_assign_ticket_themselves === false) {
          setCanAssignTicketThemselves("false");
        } else {
          setCanAssignTicketThemselves("");
        }

        // --------------------------
        res.data.data.can_assign_ticket_to_others === false
          ? setCanAssignTicketToOthers("false")
          : setCanAssignTicketToOthers("");
        // --------------------------

        if (res.data.data.can_remove_ticket_from_themselves === false) {
          setCanRemoveTicketFromThemselves("false");
        } else {
          setCanRemoveTicketFromThemselves("");
        }

        // --------------------------
        if (res.data.data.can_remove_ticket_assignee === false) {
          setCanRemoveTicketAssignee("false");
        } else {
          setCanRemoveTicketAssignee("");
          setCanRemoveTicketFromThemselves("false");
        }

        // --------------------------
        res.data.data.can_change_ticket_assignee === false
          ? setCanChangeTicketAssignee("false")
          : setCanChangeTicketAssignee("");
        // --------------------------
      });
  };

  const categoryChangeSelectClose = () => {
    if (ticketCategoryChanger === "true") {
      setTicketCategoryChanger("false");
    }
  };

  const removeAssign = () => {
    axios
      .post(
        `${URL}/api/tickets/${circle.id}/remove_assignee/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeDetect(!circle.stateRefresh);
        setError({
          open: true,
          title: "Uğurlu əməliyyat!",
          description: res.data.success_message,
          success: res.data.success,
        });
        getHistories();
        setAssignState2("");
        setRefresh(!refresh);
        setAssignedOrNo2("");
        setAssignedOrNo("false");
        setAssignedID("");
        setIfTicketAssigneeMe("false");
      })
      .catch((res) => {
        setError({
          open: true,
          title: "Uğursuz əməliyyat",
          description: res.response.data.data.error,
          success: res.response.data.success,
        });
      });
  };

  const getHistories = () => {
    axios
      .get(`${URL}/api/tickets/${circle.id}/history/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch(() => {})
      .then((res) => {
        setHistories(res?.data?.data);
      });
  };

  const getCategories = (props) => {
    axios(`${URL}/api/categories/?keyword=OmniTicket`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        // keyword: "CategoryChange",
        page_size: 9999999,
        search: props,
        ordering: "name",
      },
    })
      .then((res) => {
        setDataCategories(res.data.data.results);
      })
      .catch(() => {});
  };

  const activeNonActive = (props) => {
    if (props === "second") {
      setActiveFirst("nonactive");
      setActiveSecond("active");
      setHistoryCommentChanger("false");
      setChangeTaskHistory("taskHistory");
    } else {
      setActiveFirst("active");
      setActiveSecond("nonactive");
      setHistoryCommentChanger("");
      setChangeTaskHistory("false");
    }
  };

  const handlechange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setTicketData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setPrevTicketDescription({ description: circle.comment });
    setUsername(circle.usernameID);
    if (circle.completedAt === "false") {
      setCanChangeTicketAssignee("false");
      setCanAssignTicketThemselves("false");
    }
  }, []);

  useEffect(
    () => {
      testAssignedOrNo();
      circle.state?.id === 4
        ? setNonAsignee("false")
        : canAssignTicketThemselves === setNonAsignee("false") &&
          circle.assigned === "İcraçı təyin edilməyib"
        ? setNonAsignee("")
        : setNonAsignee("false");
    },
    [token],
    [refresh]
  );

  useEffect(() => {
    if (circle.assignedMe) {
      getHistories();
      getAvailableWorkflows();
      getComments();
      getAvialableUsersToAssign();
      getCategories();
      getPermissions();
    }
  }, [circle.assignedMe]);

  return (
    <div className="ticketLabelParent">
      {error.open ? (
        <DialogCloseButton setError={setError} error={error} />
      ) : null}
      <Dialog
        defaultOpen={circle.assignedMe}
        onOpenChange={(open) => {
          if (open === false) {
            circle.setStateRefresh(changeDetect);
            if (circle.assignedMe) {
              circle?.setAssignedOpen({ isAssignedMe: false });
            }
          } else {
            getHistories();
            getAvailableWorkflows();
            getComments();
            getAvialableUsersToAssign();
            getCategories();
            getPermissions();
          }
        }}
      >
        <DialogTrigger
          className="ticketLabel"
          style={{
            // display: circle.assignedMe ? "none" : "",
            display:
              circle.ownerGroup === undefined || circle.assignedMe
                ? "none"
                : "",
            backgroundColor: `${circle.workflowColor}`,
          }}
        >
          <div>
            <p
              style={{
                fontSize: circle?.ticketLabel?.length < 25 ? "14px" : "12px",
              }}
            >
              {circle.ticketLabel}
            </p>
          </div>
          <TicketLabelCircle className="ticketLabelCircle" />
        </DialogTrigger>

        <div
          className="ticket"
          style={{
            display: circle.assignedMe ? "none" : "block",
          }}
        >
          <div className={circle.background}>
            <div className="ticketTitle">
              <div className="ticketTitleL">
                <span>{circle.ticketNum}</span>
                <span>{circle.category}</span>
                <h3>{circle.ownerGroup}</h3>
              </div>
              <div className="ticketTitleR">
                <div>
                  <img
                    src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
                    alt=""
                  />
                </div>
                <div>
                  <h3>{circle.createdBy}</h3>
                  <h4>Created by</h4>
                </div>
              </div>
            </div>
            <span className="ticketDateSpan">
              Yaradıldı: {circle.date} {circle.time}
            </span>
            <div className="ticketContent">
              <div className="ticketContentTitle">
                {customFieldsState?.map((item) => {
                  if (item?.field_definition?.name === "customer_number") {
                    return (
                      <>
                        <span
                          key={item?.field_definition.id}
                          onClick={() => {
                            navigator.clipboard.writeText(item?.value);
                          }}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {item?.value}
                        </span>
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={`https://web.whatsapp.com/send?phone=${
                            item?.value === undefined
                              ? ""
                              : item?.value === null
                              ? ""
                              : item?.value[0] === "0"
                              ? `994${item?.value?.slice(1)}`
                              : item?.value[0] === "+"
                              ? item?.value?.slice(1)
                              : `${item?.value}`
                          }`}
                          style={{
                            marginLeft: "0",
                            display:
                              item?.field_definition?.name === "customer_number"
                                ? ""
                                : "none",
                          }}
                          className="numberToWp"
                        >
                          <WhatsAppIconForTicket />
                        </Link>
                      </>
                    );
                  } else if (
                    item?.field_definition?.name === "full_name" ||
                    item?.field_definition.name === "seria"
                  ) {
                    return (
                      <span key={item?.field_definition.id}>{item?.value}</span>
                    );
                  }
                })}
              </div>
              <div className="ticketContentComment">
                <span>
                  <Linkify text={circle?.comment?.slice(0, 150)} />
                  {circle?.comment?.length > 148 ? "....." : ""}
                </span>
              </div>
              <hr />
              <div className="ticketWhoseTT">
                <DialogTrigger className="ticketWhose">
                  <div className="icraciTT">
                    <img
                      src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
                      alt=""
                    />
                    <div>
                      <h3>{circle.assigned}</h3>
                      <span>{circle.icraci}</span>
                    </div>
                  </div>
                  <div className={postState}>
                    {circleColor === "Start" ? (
                      <BlueCircle />
                    ) : circleColor === "done" ? (
                      <BlackCircle />
                    ) : (
                      <GreenCircle />
                    )}
                    {circle.state?.label}
                  </div>
                  <div className={postState === "" ? "false" : ""}>
                    {circleColor === "Start" ? (
                      <BlueCircle />
                    ) : circleColor === "done" ? (
                      <BlackCircle />
                    ) : (
                      <GreenCircle />
                    )}
                    {stageData}
                  </div>

                  <span>Ətraflı bax</span>
                </DialogTrigger>
              </div>
              <DialogTrigger className="dialogTriggerSeeDetails">
                <p className="seeDetailsForResponsive">Ətraflı bax</p>
              </DialogTrigger>
            </div>
          </div>
        </div>

        <DialogContent
          className="ticketDetailsHome"
          onKeyDown={(e) => shortcuts(e)}
        >
          <div className="ticketDetails">
            <div className="detailsTitle">
              <div className="detailsTitleL">
                <span className="pl-3">{circle.ticketNum}</span>
                <div>
                  <span className={categoryClass}>{circle.category}</span>
                  <span className={categoryClass === "" ? "false" : ""}>
                    {categoryData}
                  </span>
                  <div className={changeCategoryPerm}>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button className="w-7 h-7 p-0" variant="none">
                          <EditCategory />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56 h-64">
                        <DropdownMenuLabel>Kategoriyalar:</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <div className="h-64 overflow-auto pb-16">
                          {dataCategories.map((item) => {
                            return (
                              <DropdownMenuCheckboxItem
                                className="hover:bg-zinc-100 cursor-pointer"
                                key={item.id}
                                value={item.id}
                                onCheckedChange={() => {
                                  handleChangeCategory(item.id);
                                }}
                              >
                                {item.name}
                              </DropdownMenuCheckboxItem>
                            );
                          })}
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <h3>{ticketGroupData}</h3>
                </div>
                <h4>
                  Yaradıldı: {circle.date} {circle.time}
                </h4>
              </div>
              <div className="ticketTTT">
                <div className="ticketTTTimg">
                  <img
                    src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
                    alt=""
                  />
                </div>
                <div className="ticketTTTwords">
                  <h5>{circle.createdBy}</h5>
                  <h6>Created by</h6>
                </div>
              </div>
              <DialogClose className="dialogCloseButton">
                <MiniClose />
              </DialogClose>
            </div>
            <div className="detailsContent" onClick={categoryChangeSelectClose}>
              <div className="detailsContentL">
                <span
                  onClick={() => {
                    setEditTicketToggle(!editTicketToggle);
                    setSaveButtonDisabled(
                      saveButtonDisabled === false ? true : saveButtonDisabled
                    );
                  }}
                  className="standartButton w-7 h-7 bg-[#F4F4F5] rounded-md flex items-center justify-center cursor-pointer absolute right-0 top-0"
                >
                  <EditComment />
                </span>
                <div className="customFieldsParent">
                  {editTicketToggle === false ? (
                    <>
                      {customFieldsState?.map((item) => {
                        return (
                          <p
                            onClick={() => {
                              if (
                                item?.field_definition?.name ===
                                "customer_number"
                              ) {
                                navigator.clipboard.writeText(item?.value);
                              }
                            }}
                            style={{
                              cursor:
                                item?.field_definition?.name ===
                                "customer_number"
                                  ? "pointer"
                                  : "",
                            }}
                            key={item.id}
                            className="customFileds"
                          >
                            <span
                              style={{ color: "#71717A", marginRight: "3px" }}
                            >
                              {item?.field_definition?.display_name + ":"}
                            </span>
                            {item?.value}
                            <Link
                              target="_blank"
                              rel="noreferrer"
                              to={`https://web.whatsapp.com/send?phone=${
                                item?.value === undefined
                                  ? ""
                                  : item?.value === null
                                  ? ""
                                  : item?.value[0] === "0"
                                  ? `994${item?.value?.slice(1)}`
                                  : item?.value[0] === "+"
                                  ? item?.value?.slice(1)
                                  : `${item?.value}`
                              }`}
                              style={{
                                display:
                                  item?.field_definition?.name ===
                                  "customer_number"
                                    ? ""
                                    : "none",
                              }}
                              className="numberToWp"
                            >
                              <WhatsAppIconForTicket />
                            </Link>
                          </p>
                        );
                      })}
                      {unmatchedFields?.map((field) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                          key={field.id}
                        >
                          <p className="customFileds">{field.display_name}:</p>
                          <Input
                            className="w-[200px]"
                            id={field.id}
                            name={field.name}
                            onChange={(e) =>
                              handleInputChangeUnmatchedFields(
                                field.id,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "12px",
                      }}
                    >
                      {fieldss?.map((field) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                          key={field?.id}
                        >
                          <p className="customFileds">
                            {field?.field_definition?.display_name}:
                          </p>
                          <Input
                            className="w-[200px]"
                            id={field?.field_definition?.name}
                            name={field?.field_definition?.name}
                            value={field?.value}
                            onChange={(e) =>
                              handleInputChange(field?.id, e.target.value)
                            }
                          />
                        </div>
                      ))}
                      {unmatchedFields?.map((field) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                          }}
                          key={field.id}
                        >
                          <p className="customFileds">{field.display_name}:</p>
                          <Input
                            className="w-[200px]"
                            id={field.id}
                            name={field.name}
                            onChange={(e) =>
                              handleInputChangeUnmatchedFields(
                                field.id,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {editTicketToggle === false ? (
                  <div
                    className={
                      prevTicketDescription?.description?.length === 0 ||
                      prevTicketDescription?.description === null
                        ? "false"
                        : "contentCommentArea"
                    }
                  >
                    <span>
                      <Linkify text={prevTicketDescription?.description} />
                    </span>
                  </div>
                ) : (
                  <div className="descriptionEditTextareaParent">
                    <Textarea
                      value={prevTicketDescription?.description}
                      onChange={(e) => {
                        setSaveButtonDisabled(false);
                        setPrevTicketDescription({
                          description: e.target.value,
                        });
                      }}
                      name="text"
                      placeholder="Qeyd əlavə edin.."
                      className="descriptionEditTextarea"
                    />
                  </div>
                )}

                <hr className="detailsContentLhr" />
                <div className="w-full flex justify-end mt-4">
                  <Button
                    disabled={saveButtonDisabled}
                    className="standartButton"
                    style={{
                      width: "113px",
                      backgroundColor: "#F4F4F5",
                      color: "black",
                    }}
                    onClick={() => {
                      editTicketData();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div className="detailsContentHR">
                <hr />
              </div>
              <div className="detailsContentR">
                <div className="detailsContentRTitle">
                  <span
                    onClick={() => activeNonActive("first")}
                    className={activeFirst}
                  >
                    Şərhlər
                  </span>
                  <div></div>
                  <span
                    onClick={() => activeNonActive("second")}
                    className={activeSecond}
                  >
                    Tarixçə
                  </span>
                </div>
                <div className={historyCommentChanger}>
                  <div
                    className="ticketsOverflow"
                    style={{
                      height:
                        visibilityForEditComment === "false" ? "390px" : "",
                    }}
                  >
                    {comments
                      .filter((item) => {
                        if (getCommentPerm === "false") {
                          return null;
                        } else {
                          return item;
                        }
                      })
                      .map((item) => {
                        const datee = new Date(
                          item.created_at
                        ).toLocaleDateString();
                        const dateee = new Date(item.created_at);
                        const hours = dateee.getHours();
                        const minutes = dateee.getMinutes();
                        const seconds = dateee.getSeconds();
                        const formattedTime = `${
                          hours < 10 ? "0" : ""
                        }${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
                          seconds < 10 ? "0" : ""
                        }${seconds}`;
                        const date = `${datee} ${formattedTime}`;
                        return (
                          <Comment
                            getHistories={getHistories}
                            setComments={setComments}
                            setVisibilityForEditComment={
                              setVisibilityForEditComment
                            }
                            ticketID={circle.id}
                            commentID={item.id}
                            key={item.id}
                            commentDate={date}
                            name={
                              item?.author?.full_name?.length === 0
                                ? item?.author?.username
                                : item?.author?.full_name
                            }
                            text={item.text}
                          />
                        );
                      })}
                  </div>
                  <div
                    className="detailsContentAddComment"
                    style={{
                      display:
                        visibilityForEditComment === "false" ? "none" : "",
                    }}
                  >
                    <div className="flex justify-between items-end pb-1">
                      <Label>Şərh əlavə et</Label>
                    </div>
                    <div className="shortMessagesParent">
                      <span
                        onClick={() => {
                          axios
                            .post(
                              `${URL}/api/tickets/${circle.id}/comments/create/`,
                              { text: "İcra olundu" },
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .catch((res) => {
                              setError({
                                open: true,
                                title: "Uğursuz əməliyyat",
                                description: res.response.data.data.error,
                                success: res.response.data.success,
                              });
                            })
                            .then((res) => {
                              setChangeDetect(!circle.stateRefresh);
                              setError({
                                open: true,
                                title: "Uğurlu əməliyyat!",
                                description: "Şərh uğurla əlavə olundu",
                                success: res.data.success,
                              });
                              setRefresh(!refresh);
                              getComments();
                              getHistories();
                              handleClickComment();
                            });
                        }}
                        className="shortMessages"
                      >
                        İcra olundu
                      </span>
                      <span
                        onClick={() => {
                          axios
                            .post(
                              `${URL}/api/tickets/${circle.id}/comments/create/`,
                              { text: "Əlaqə saxlanıldı" },
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .catch((res) => {
                              setError({
                                open: true,
                                title: "Uğursuz əməliyyat",
                                description: res.response.data.data.error,
                                success: res.response.data.success,
                              });
                            })
                            .then((res) => {
                              setChangeDetect(!circle.stateRefresh);
                              setError({
                                open: true,
                                title: "Uğurlu əməliyyat!",
                                description: "Şərh uğurla əlavə olundu",
                                success: res.data.success,
                              });
                              setRefresh(!refresh);
                              getComments();
                              getHistories();
                              handleClickComment();
                            });
                        }}
                        className="shortMessages"
                      >
                        Əlaqə saxlanıldı
                      </span>
                      <span
                        onClick={() => {
                          axios
                            .post(
                              `${URL}/api/tickets/${circle.id}/comments/create/`,
                              { text: "Əlaqə saxlanılacaq" },
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .catch((res) => {
                              setError({
                                open: true,
                                title: "Uğursuz əməliyyat",
                                description: res.response.data.data.error,
                                success: res.response.data.success,
                              });
                            })
                            .then((res) => {
                              setChangeDetect(!circle.stateRefresh);
                              setError({
                                open: true,
                                title: "Uğurlu əməliyyat!",
                                description: "Şərh uğurla əlavə olundu",
                                success: res.data.success,
                              });
                              setRefresh(!refresh);
                              getComments();
                              getHistories();
                              handleClickComment();
                            });
                        }}
                        className="shortMessages"
                      >
                        Əlaqə saxlanılacaq
                      </span>
                    </div>
                    <div className="textAreaParent">
                      <Textarea
                        ref={ref}
                        value={ticketData.text}
                        onChange={handlechange}
                        name="text"
                        className="ticketCommentTextArea"
                        placeholder="Şərh yazın.."
                      />
                      <div
                        onClick={() => setSmileToggle(!smileToggle)}
                        className="smileToggleBtn"
                      >
                        <Smile />
                      </div>
                      <div
                        style={{
                          display: smileToggle === false ? "none" : "",
                        }}
                        className="smiles"
                      >
                        <div className="smileTriangle"></div>
                        <div className="smileItemParent">
                          <span
                            onClick={() => {
                              sendSmileFunc("👍🏻");
                            }}
                            className="smileItem"
                          >
                            👍🏻
                          </span>
                          <span
                            onClick={() => {
                              sendSmileFunc("✅");
                            }}
                            className="smileItem"
                          >
                            ✅
                          </span>
                          <span
                            onClick={() => {
                              sendSmileFunc("❌");
                            }}
                            className="smileItem"
                          >
                            ❌
                          </span>
                          <span
                            onClick={() => {
                              sendSmileFunc("🤝");
                            }}
                            className="smileItem"
                          >
                            🤝
                          </span>
                          <span
                            onClick={() => {
                              sendSmileFunc("😂");
                            }}
                            className="smileItem"
                          >
                            😂
                          </span>
                          <span
                            onClick={() => {
                              sendSmileFunc("👌🏻");
                            }}
                            className="smileItem"
                          >
                            👌🏻
                          </span>
                        </div>
                      </div>
                      <div>
                        <div onClick={() => postComment()}>
                          <button
                          // disabled={!createCommentPerm}
                          >
                            Göndər{"  "}
                            <ForwardIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={changeTaskHistory}>
                  {histories
                    .filter((item) => {
                      if (getHistoryPerm === "false") {
                        return null;
                      } else {
                        return item;
                      }
                    })
                    .map((item, index) => {
                      const date = item.action_time?.split("T");
                      const fullDate = `${date[0]} ${date[1].split(".")[0]}`;
                      return (
                        <TaskHistoryChanger
                          key={index}
                          name={
                            item?.user?.full_name?.length === 0
                              ? item?.user?.username
                              : item?.user?.full_name
                          }
                          status={item.change_message}
                          date={fullDate}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <hr className="detailsContentEndHR" />
            <div className="detailsContentEnd">
              <div className="contentWhose">
                <div className="assignTicket">
                  <h5 className={nonAsignee}>{circle.assigned}</h5>
                  <h4 className={assignedOrNo2}>
                    <span
                      className={canAssignTicketThemselves}
                      onClick={setToMeFunc}
                    >
                      Mənə təyin et
                    </span>
                  </h4>
                  <span className="assigneeHR">
                    <span className={assignedOrNo2}>
                      <span className={canAssignTicketThemselves}>l</span>
                    </span>
                  </span>
                  <h6 className={assignedOrNo2}>
                    <span className={canAssignTicketToOthers}>
                      <Select
                        value={assignedID}
                        onValueChange={(id) => {
                          handleUserToAssign(id);

                          setAssignedID(id);
                        }}
                      >
                        <SelectTrigger className="border-none w-[158px] h-12 whitespace-nowrap">
                          <SelectValue placeholder="İcraçı təyin et" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup className="h-52 overflow-auto">
                            <SelectLabel>Icraçı seçin</SelectLabel>
                            {avialableUsersToAssign &&
                              avialableUsersToAssign.map((user) => {
                                return (
                                  <SelectItem
                                    className="hover:bg-zinc-100 cursor-pointer"
                                    value={user.id}
                                    key={user.id}
                                  >
                                    {user?.full_name?.length === 0
                                      ? user?.username
                                      : user?.full_name}
                                  </SelectItem>
                                );
                              })}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </span>
                  </h6>
                  <div className={assignedOrNo}>
                    <img
                      className="assigneeIMG"
                      src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
                      alt=""
                    />
                    <h5 className={assignState2}>{circle.assigned}</h5>
                    <h5 className={assignState2 === "" ? "false" : ""}>
                      {assignData}
                    </h5>
                    <h2 className={canRemoveTicketAssignee}>
                      <p onClick={() => removeAssign()} className="miniClose">
                        <MiniClose />
                      </p>
                    </h2>
                    <h2 className={canRemoveTicketFromThemselves}>
                      <p onClick={() => removeAssign()} className="miniClose">
                        <MiniClose />
                      </p>
                    </h2>
                  </div>
                  <span className="assigneeHR">
                    <span className={assignedOrNo}>
                      <span className={canAssignTicketThemselves}>l</span>
                    </span>
                  </span>
                  <h6 className={assignedOrNo}>
                    <span className={canChangeTicketAssignee}>
                      <Select
                        className=""
                        value={assignedID}
                        onValueChange={(id) => {
                          handleUserToAssign(id);
                          setAssignedID(id);
                        }}
                      >
                        <SelectTrigger className="border-none w-[155px] whitespace-nowrap">
                          <SelectValue
                            className="changeAssigneee"
                            placeholder="İcraçını dəyiş"
                          />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup className="h-52 overflow-auto">
                            <SelectLabel>Icraçı seçin</SelectLabel>
                            {avialableUsersToAssign &&
                              avialableUsersToAssign.map((user) => {
                                return (
                                  <SelectItem
                                    className="hover:bg-zinc-100 cursor-pointer"
                                    value={user.id}
                                    key={user.id}
                                  >
                                    {user?.full_name?.length === 0
                                      ? user?.username
                                      : user?.full_name}
                                  </SelectItem>
                                );
                              })}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </span>
                  </h6>
                </div>
                <div className="prevNextStage">
                  <h4>
                    {circle.dateCompleted} {circle.timeCompleted}
                  </h4>
                  <div>
                    {circleColor === "Start" ? (
                      <BlueCircle />
                    ) : circleColor === "done" ? (
                      <BlackCircle />
                    ) : (
                      <GreenCircle />
                    )}
                    <h3 className={postState}>{circle.state?.label}</h3>
                    <h3>{stageData}</h3>
                  </div>
                  <span className={changeStagePerm}>
                    <h3 className="flex items-center gap-4">
                      {workflowChanger === "" ? (
                        <Dialog>
                          <DialogTrigger asChild>
                            <h5 className={ifTicketAssigneeMe}>
                              <RightArrowWithLine />
                            </h5>
                          </DialogTrigger>
                          <DialogContent
                            style={{
                              maxHeight: "90vh",
                              overflow: "auto",
                            }}
                          >
                            <DialogHeader>
                              <DialogTitle className="pb-5">
                                Növbəti addımı təyin edin
                              </DialogTitle>
                              <DialogClose
                                style={{
                                  position: "absolute",
                                  top: "20px",
                                  right: "20px",
                                }}
                              >
                                <MiniClose />
                              </DialogClose>
                            </DialogHeader>
                            <hr className="pb-5" />
                            {nextAvailableWorkflows.map((item) => {
                              return (
                                <DialogClose asChild>
                                  <div
                                    onClick={() =>
                                      postAvailableWorkflows(item.id)
                                    }
                                    className="availableWorkflows"
                                    key={item.id}
                                  >
                                    <span>{item.name}</span>
                                  </div>
                                </DialogClose>
                              );
                            })}
                          </DialogContent>
                        </Dialog>
                      ) : (
                        <h5
                          className={ifTicketAssigneeMe}
                          onClick={() => postNextStage()}
                        >
                          <RightArrowWithLine />
                        </h5>
                      )}
                    </h3>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Ticket;
