// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changepass {
  padding: 20px;
  border: 1px solid #e4e4e7;
  border-radius: 12px;
}
.changepass span {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.changepass hr {
  width: 100%;
  color: #E4E4E7;
  margin-top: 16px;
  margin-bottom: 16px;
}

.notificationSwitch {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
  border: 1px solid #E4E4E7;
  border-radius: 12px;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/style/pages/settings.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,WAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".changepass {\n  padding: 20px;\n  border: 1px solid #e4e4e7;\n  border-radius: 12px;\n  span {\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px;\n  }\n  hr{\n    width: 100%;\n    color: #E4E4E7;\n    margin-top: 16px;\n    margin-bottom: 16px;\n  }\n}\n.notificationSwitch{\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 24px;\n    border: 1px solid #E4E4E7;\n    border-radius: 12px;\n    margin-bottom: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
