// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboardTicketAreaTitle {
  display: flex;
  padding: 20px;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight {
  margin-left: 10px;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightTT {
  display: flex;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightTT p {
  color: #71717a;
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightTT p span {
  color: #09090b;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightEnd {
  display: flex;
  gap: 10px;
  margin-top: 9px;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightEnd p {
  color: #71717a;
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.dashboardTicketAreaTitle .dashboardTicketAreaRight .dashboardTicketAreaRightEnd p span {
  color: #09090b;
}`, "",{"version":3,"sources":["webpack://./src/style/component/dashboardTicketAreaTitle.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF;AAAE;EACE,iBAAA;AAEJ;AADI;EACE,aAAA;AAGN;AAFM;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAIR;AAHQ;EACE,cAAA;AAKV;AADI;EACE,aAAA;EACA,SAAA;EACA,eAAA;AAGN;AAFM;EACE,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAIR;AAHQ;EACE,cAAA;AAKV","sourcesContent":[".dashboardTicketAreaTitle {\n  display: flex;\n  padding: 20px;\n  .dashboardTicketAreaRight {\n    margin-left: 10px;\n    .dashboardTicketAreaRightTT {\n      display: flex;\n      p {\n        color: #71717a;\n        font-family: inter;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 20px;\n        span {\n          color: #09090b;\n        }\n      }\n    }\n    .dashboardTicketAreaRightEnd {\n      display: flex;\n      gap: 10px;\n      margin-top: 9px;\n      p {\n        color: #71717a;\n        font-family: inter;\n        font-weight: 500;\n        font-size: 14px;\n        line-height: 20px;\n        span {\n          color: #09090b;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
