import { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import React from "react";
import { SendIcon } from "../svg";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Calendar as CalendarIcon } from "lucide-react";
import { addDays, format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";

// import Select from "react-select";
// import { format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";
// import { Calendar } from "../components/ui/calendar";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "../components/ui/popover";
// import { cn } from "../lib/utils";

const ReportWorkflow = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  settings,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [date, setDate] = useState();
  const [loadingText, setLoadingText] = useState("loadingText");
  const [exportURL, setExportURL] = useState("");
  const [usernameClass, setusernameClass] = useState("false");

  const getReport = () => {
    setusernameClass("false");
    setData([]);
    setLoadingText("loadingText");
    axios
      .get(`${URL}/api/tickets/report/workflows/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: date?.from,
          end_date: date?.to,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setusernameClass("");
        setLoadingText("false");
      })
      .catch(() => {});
  };

  const getReportExport = () => {
    axios
      .get(`${URL}/api/tickets/report/workflows/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          as_xls: true,
          start_date: date?.from,
          end_date: date?.to,
        },
      })
      .then((res) => {
        setExportURL(res.data.data.url);
      })
      .catch(() => {});
  };

  const sideBarCheck = () => {
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (
          res.data.data.some((item) => item.label === "REPORT_PER_WORKFLOW")
        ) {
        } else {
          navigate("/*");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    sideBarCheck();
    getReport();
    getReportExport();
  }, [date]);

  return (
    <div>
      <SideBar
        settings={settings}
        createdByMeTickets={createdByMeTickets}
        workflowReportClass={"workflowReport"}
        faq={faq}
        reportPerCategory={reportPerCategory}
        reportPerUser={reportPerUser}
        reportAdvanced={reportAdvanced}
        allTicAcc={allTicAcc}
        myGroupTicAcc={myGroupTicAcc}
        dashboardAcc={dashboardAcc}
        myTicAcc={myTicAcc}
        setSideBarReport={setSideBarReport}
        sideBarReport={sideBarReport}
        myGroupTicketCount={myGroupTicketCount}
        workflowReport={workflowReport}
        userWorkflowReport={userWorkflowReport}
        dragAndDrop={dragAndDrop}
      />
      <TopBar
        setUser={setUser}
        username={propsUsername}
        nameForDisplay={nameForDisplay}
        unreadNot={unreadNot}
        notificationRefresh={notificationRefresh}
        setNotificationRefresh={setNotificationRefresh}
        notificationData={notificationData}
        setNotificationCount={setNotificationCount}
      />
      <div className="reportsTT">
        <div className="reports">
          <div className="reportsTitle">
            <h2>Workflow Report</h2>
            <div className="reportsTitleR">
              <div className="dateFilterFirst">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="date"
                      variant={"calendar"}
                      className={cn(
                        "w-[240px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {date?.from ? (
                        date.to ? (
                          <>
                            {format(date.from, "LLL dd, y")} -{" "}
                            {format(date.to, "LLL dd, y")}
                          </>
                        ) : (
                          format(date.from, "LLL dd, y")
                        )
                      ) : (
                        <span className="calendarText">Tarix</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      initialFocus
                      mode="range"
                      defaultMonth={date?.from}
                      selected={date}
                      onSelect={setDate}
                      numberOfMonths={2}
                    />
                  </PopoverContent>
                </Popover>
              </div>
              <div className="dateFilterFirst">
                <Link target="_blank" rel="noreferrer" to={exportURL}>
                  <Button variant="mybtn2">
                    <SendIcon />
                    <span className="newTicketText">Export</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="dateFilterSecond">
            <Link target="_blank" rel="noreferrer" to={exportURL}>
              <Button className="w-full ml-0" variant="mybtn2">
                <SendIcon />
                <span className="newTicketText">Export</span>
              </Button>
            </Link>
          </div>
          <div className="dateFilterSecond">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"calendar"}
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date?.from ? (
                    date.to ? (
                      <>
                        {format(date.from, "LLL dd, y")} -{" "}
                        {format(date.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span className="calendarText">Tarix</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={setDate}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
          </div>
          <span className={loadingText}>Yüklənir...</span>
          <div className="customersParent">
            <table id="customers">
              <thead>
                <tr>
                  <th className={usernameClass}>Workflow</th>
                  <th>{data[0]?.all_tasks_count?.label}</th>
                  <th>{data[0]?.completed_tasks_count?.label}</th>
                  <th>{data[0]?.open_tasks_count?.label}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.workflow?.name}</td>
                      <td>{item?.all_tasks_count?.total}</td>
                      <td>{item?.completed_tasks_count?.total}</td>
                      <td>{item?.open_tasks_count?.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportWorkflow;
