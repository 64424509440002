import React from "react";
const processText = (text) => {
  const regex = /(https?:\/\/[^\s]+)|(\*\*([^*]+)\*\*)/g;

  const parts = [];
  let lastIndex = 0;

  text?.replace(regex, (match, url, boldText, boldContent, offset) => {
    if (offset > lastIndex) {
      parts.push(text.slice(lastIndex, offset));
    }
    if (url) {
      parts.push(
        <a
          href={url}
          key={offset}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: "#6658ea" }}
        >
          {url}
        </a>
      );
    } else if (boldText) {
      parts.push(<strong key={offset}>{boldContent}</strong>);
    }
    lastIndex = offset + match.length;
  });
  if (lastIndex < text?.length) {
    parts.push(text.slice(lastIndex));
  }
  return parts;
};
const Linkify = ({ text }) => {
  return <div>{processText(text)}</div>;
};

export default Linkify;
