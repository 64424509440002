// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  flex-direction: column;
}

.tab-buttons {
  display: flex;
  height: 38px;
  padding: 4px 10px;
  align-items: center;
}

.tabContent {
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: 44px;
  gap: 10px;
}
.tabContent p {
  color: #84818a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.tab-buttons:last-child {
  margin: 100px;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  transition: border-bottom;
}

.tab-button.active {
  border-bottom: 2px solid #007bff;
}`, "",{"version":3,"sources":["webpack://./src/style/component/tab.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAEF;AADE;EACE,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGJ;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;AAGF;;AAAA;EACE,gCAAA;AAGF","sourcesContent":[".tabs {\n  display: flex;\n  flex-direction: column;\n}\n\n.tab-buttons {\n  display: flex;\n  height: 38px;\n  padding: 4px 10px;\n  align-items: center;\n}\n.tabContent {\n  display: flex;\n  text-align: center;\n  align-items: center;\n  margin-right: 44px;\n  gap: 10px;\n  p {\n    color: #84818a;\n    font-family: Montserrat;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 14px;\n  }\n}\n.tab-buttons:last-child {\n  margin: 100px;\n}\n\n.tab-button {\n  padding: 10px 20px;\n  cursor: pointer;\n  transition: border-bottom;\n}\n\n.tab-button.active {\n  border-bottom: 2px solid #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
