import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import Ticket from "../component/Ticket";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { useEffect, useState } from "react";
import axios from "axios";
import { DialogCloseButton } from "../component/Alert";
import { useNavigate } from "react-router-dom";

const Home = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  settings,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [ticketAxiosUsername, setTicketAxiosUsername] = useState("");
  const [openTicket, setOpenTicket] = useState("all");
  const ticketNotFound = [{}];
  const [ticketNotFoundText, setTicketNotFoundText] = useState("Yüklənir...");
  const [query, setQuery] = useState("");

  const [prevPage, setPrevPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClass, setCurrentPageClass] = useState("false");
  const [nextClass, setNextClass] = useState("");
  const [prevClass, setPrevClass] = useState("false");
  const [firstPagClass, setFirstPagClass] = useState("blackNumBtn");
  const [lastPagClass, setLastPagClass] = useState("secondary");
  const [allPagClass, setAllPagClass] = useState("secondary");
  const [currentPagVariant, setCurrentPagVariant] = useState("secondary");
  const [paginition, setPaginition] = useState("false");
  const [totalPageClass, setTotalPageClass] = useState("");
  const [lastPage, setLastPage] = useState("");

  const [ticketOrderingFilter, setTicketOrderingFilter] =
    useState("-created_at");

  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const paginitionFunction = (res) => {
    if (res.data.data.next?.split("page=")[1].indexOf("&") === -1) {
      setNextPage(res.data.data.next?.split("page=")[1]);
    } else {
      setNextPage(
        res.data.data.next
          ?.split("page=")[1]
          .substring(0, res.data.data.next?.split("page=")[1].indexOf("&"))
      );
    }
    setCurrentPagVariant("blackNumBtn");
    setAllPagClass("secondary");

    if (
      res.data.data.previous?.split("page=")[1] === undefined ||
      res.data.data.previous
        ?.split("page=")[1]
        .substring(
          0,
          res.data.data.previous?.split("page=")[1].indexOf("&")
        ) === undefined
    ) {
      setPrevPage(2);
    } else {
      if (res.data.data.previous?.split("page=")[1].indexOf("&") === -1) {
        setPrevPage(res.data.data.previous?.split("page=")[1]);
      } else {
        setPrevPage(
          res.data.data.previous
            ?.split("page=")[1]
            .substring(
              0,
              res.data.data.previous?.split("page=")[1].indexOf("&")
            )
        );
      }
    }

    setCurrentPage(res.data.data.current_page);
    setLastPage(res.data.data.total_pages);

    res.data.data.total_pages === 1
      ? setPaginition("false")
      : setPaginition("ticketsPaginition");

    res.data.data.total_pages === 1
      ? setTotalPageClass("false")
      : setTotalPageClass("");

    res.data.data.current_page === 1
      ? setFirstPagClass("blackNumBtn")
      : setFirstPagClass("secondary");

    res.data.data.current_page === res.data.data.total_pages
      ? setLastPagClass("blackNumBtn")
      : setLastPagClass("secondary");

    res.data.data.current_page > 2 ? setPrevClass("") : setPrevClass("false");

    if (
      res.data.data.current_page === res.data.data.total_pages - 1 ||
      res.data.data.total_pages === res.data.data.current_page
    ) {
      setNextClass("false");
    } else {
      setNextClass("");
    }

    if (
      res.data.data.current_page === 1 ||
      res.data.data.current_page === res.data.data.total_pages
    ) {
      setCurrentPageClass("false");
    } else {
      setCurrentPageClass("");
    }
  };

  const [cancelToken, setCancelToken] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Cancel the previous request
      if (cancelToken) {
        cancelToken.cancel();
      }

      const source = axios.CancelToken.source();
      setCancelToken(source);

      if (openTicket === "opened") {
        setTicketNotFoundText("Yüklənir...");
        axios(`${URL}/api/tickets/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            status: 0,
            search: query,
            ordering: ticketOrderingFilter,
          },
        })
          .then((res) => {
            res.data.data.count === 0
              ? setTicketNotFoundText("Ticket tapılmadı")
              : setTicketNotFoundText();
            setData(res.data.data.results);
            paginitionFunction(res);
          })
          .catch(() => {});
      } else if (openTicket === "closed") {
        setTicketNotFoundText("Yüklənir...");
        axios(`${URL}/api/tickets/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            status: 1,
            search: query,
            ordering: ticketOrderingFilter,
          },
        })
          .then((res) => {
            res.data.data.count === 0
              ? setTicketNotFoundText("Ticket tapılmadı")
              : setTicketNotFoundText();
            setData(res.data.data.results);
            paginitionFunction(res);
          })
          .catch(() => {});
      } else if (openTicket === "all") {
        setTicketNotFoundText("Yüklənir...");
        axios(`${URL}/api/tickets/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            search: query,
            ordering: ticketOrderingFilter,
          },
        })
          .then((res) => {
            res.data.data.count === 0
              ? setTicketNotFoundText("Ticket tapılmadı")
              : setTicketNotFoundText();
            setData(res.data.data.results);
            paginitionFunction(res);
          })
          .catch(() => {});
      }
    }, 700);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query]);

  const getTickets = (props) => {
    if (openTicket === "opened") {
      setTicketNotFoundText("Yüklənir...");
      axios(`${URL}/api/tickets/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: 0,
          page: props,
          search: query,
          ordering: ticketOrderingFilter,
        },
      })
        .then((res) => {
          res.data.data.count === 0
            ? setTicketNotFoundText("Ticket tapılmadı")
            : setTicketNotFoundText();
          setData(res.data.data.results);
          paginitionFunction(res);
        })
        .catch(() => {});
    } else if (openTicket === "closed") {
      setTicketNotFoundText("Yüklənir...");
      axios(`${URL}/api/tickets/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          status: 1,
          page: props,
          search: query,
          ordering: ticketOrderingFilter,
        },
      })
        .then((res) => {
          res.data.data.count === 0
            ? setTicketNotFoundText("Ticket tapılmadı")
            : setTicketNotFoundText();
          setData(res.data.data.results);
          paginitionFunction(res);
        })
        .catch(() => {});
    } else if (openTicket === "all") {
      setTicketNotFoundText("Yüklənir...");
      axios(`${URL}/api/tickets/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          search: query,
          page: props,
          ordering: ticketOrderingFilter,
        },
      })
        .then((res) => {
          res.data.data.count === 0
            ? setTicketNotFoundText("Ticket tapılmadı")
            : setTicketNotFoundText();
          setData(res.data.data.results);
          paginitionFunction(res);
        })
        .catch(() => {});
    }
  };

  const getMyUserHome = () => {
    axios
      .get(`${URL}/api/auth/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTicketAxiosUsername(res.data.data.id);
      })
      .catch(() => {
        setUser(false);
      });
  };

  const sideBarCheck = () => {
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data.some((item) => item.label === "TICKET_ALL")) {
        } else {
          navigate("/*");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getTickets(currentPage);
    }, 180000);
    return () => clearInterval(intervalId);
  }, [refresh, token, openTicket]);

  useEffect(() => {
    getTickets(currentPage);
  }, [refresh, token, openTicket, ticketOrderingFilter]);

  useEffect(() => {
    sideBarCheck();
    getMyUserHome();
  }, []);

  return (
    <>
      <div className="home">
        {error.open ? (
          <DialogCloseButton setError={setError} error={error} />
        ) : null}
        <SideBar
          settings={settings}
          createdByMeTickets={createdByMeTickets}
          propsAll={"allTicketsVisit"}
          faq={faq}
          reportPerCategory={reportPerCategory}
          reportPerUser={reportPerUser}
          reportAdvanced={reportAdvanced}
          myGroupTicAcc={myGroupTicAcc}
          dashboardAcc={dashboardAcc}
          myTicAcc={myTicAcc}
          setSideBarReport={setSideBarReport}
          sideBarReport={sideBarReport}
          myGroupTicketCount={myGroupTicketCount}
          allTicAcc={allTicAcc}
          workflowReport={workflowReport}
          userWorkflowReport={userWorkflowReport}
          dragAndDrop={dragAndDrop}
        />
        <TopBar
          setUser={setUser}
          username={propsUsername}
          nameForDisplay={nameForDisplay}
          unreadNot={unreadNot}
          notificationRefresh={notificationRefresh}
          setNotificationRefresh={setNotificationRefresh}
          notificationData={notificationData}
          setNotificationCount={setNotificationCount}
        />
        <div className="tickets">
          <div className="ticketsContent">
            <div className="ticketsTitleTT">
              <div className="ticketsTitle">
                <div className="ticketTitleTabs">
                  <div className="ticketFilter">
                    <div className="searchInp">
                      <Input
                        size="lg"
                        type="number"
                        placeholder="Tiket axtar"
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>
                    <Select
                      onValueChange={(value) => {
                        setCurrentPage();
                        setOpenTicket(value);
                      }}
                    >
                      <SelectTrigger className="w-[200px]">
                        <SelectValue placeholder="Bütün tiketlər" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="all">Bütün tiketlər</SelectItem>
                          <SelectItem value="opened">
                            Bütün açıq tiketlər
                          </SelectItem>
                          <SelectItem value="closed">
                            Bütün bağlanmış tiketlər
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <Select
                  onValueChange={(e) => {
                    setTicketOrderingFilter(e);
                    setCurrentPage(1);
                  }}
                >
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Tarix üzrə yenilər" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {/* <SelectLabel>Status seçin</SelectLabel> */}
                      <SelectItem value="-created_at">
                        Tarix üzrə yenilər
                      </SelectItem>
                      <SelectItem value="created_at">
                        Tarix üzrə köhnələr
                      </SelectItem>
                      <SelectItem value="-updated_at">
                        Son dəyişlənlərə görə
                      </SelectItem>
                      <SelectItem value="-comment_created_at">
                        Son rəylər üzrə
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="ticketsArea">
              {data.length === 0
                ? ticketNotFound.map((index) => {
                    return (
                      <h1 className="text-xl ml-3 text-rose-700" key={index}>
                        {ticketNotFoundText}
                      </h1>
                    );
                  })
                : data.map((item) => {
                    const datee = new Date(
                      item.created_at
                    ).toLocaleDateString();
                    const dateee = new Date(item.created_at);
                    const hours = dateee.getHours();
                    const minutes = dateee.getMinutes();
                    const seconds = dateee.getSeconds();
                    const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
                      minutes < 10 ? "0" : ""
                    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

                    const dateCompleted = new Date(
                      item?.completed_at
                    ).toLocaleDateString();
                    const dateCompleted2 = new Date(item?.completed_at);
                    const hours2 = dateCompleted2.getHours();
                    const minutes2 = dateCompleted2.getMinutes();
                    const seconds2 = dateCompleted2.getSeconds();
                    const formattedTime2 = `${
                      hours2 < 10 ? "0" : ""
                    }${hours2}:${minutes2 < 10 ? "0" : ""}${minutes2}:${
                      seconds2 < 10 ? "0" : ""
                    }${seconds2}`;
                    return (
                      <Ticket
                        key={item.id}
                        // open={assignedOpen}
                        id={item.id}
                        customFields={item?.fields}
                        requestTypeFields={
                          item?.current_category?.request_type?.fields
                        }
                        completedAt={item.completed_at != null ? "false" : ""}
                        ifAssigneMePost={
                          item?.assignee?.username ===
                          localStorage.getItem("username")
                            ? ""
                            : "false"
                        }
                        ticketNum={"Tiket# " + item.id}
                        customerName={item.title}
                        comment={item.description}
                        setStateRefresh={setRefresh}
                        stateRefresh={refresh}
                        workflowColor={item?.current_workflow?.color}
                        currentWorkflow={item?.current_workflow?.stages}
                        background={
                          item?.assignee === null
                            ? "ticket22"
                            : item.completed_at != null
                            ? "ticketEnd"
                            : "ticketInProgress"
                        }
                        state={item?.current_stage}
                        createdBy={
                          item?.created_by?.full_name.length === 0
                            ? item.created_by.username
                            : item?.created_by?.full_name
                        }
                        ownerGroup={item?.owner_group?.name}
                        usernameID={ticketAxiosUsername}
                        category={item?.current_category?.name}
                        icraci={item?.assignee ? "icraçı" : ""}
                        assigneeNull={item?.assignee}
                        assigned={
                          item.assignee
                            ? item?.assignee?.full_name.length === 0
                              ? item?.assignee?.username
                              : item?.assignee?.full_name
                            : "İcraçı təyin edilməyib"
                        }
                        date={datee}
                        time={formattedTime}
                        dateCompleted={
                          item.completed_at === null
                            ? ""
                            : `Tamamlandı:${dateCompleted}`
                        }
                        timeCompleted={
                          item.completed_at === null ? "" : formattedTime2
                        }
                        phoneNum={item.phone_number}
                        cashier_id={item?.cashier_id}
                        voen={item?.voen}
                        ticketLabel={item?.current_workflow?.name}
                      />
                    );
                  })}
            </div>
            <div className={paginition}>
              <Button
                onClick={() => {
                  getTickets(1);
                }}
                variant={firstPagClass}
              >
                1
              </Button>
              <Button
                onClick={() => getTickets(prevPage)}
                variant="secondary"
                className={prevClass}
              >
                {prevPage !== null ? prevPage : "done"}
              </Button>
              <Button className={currentPageClass} variant={currentPagVariant}>
                {currentPage}
              </Button>
              <Button
                onClick={() => getTickets(nextPage)}
                variant="secondary"
                className={nextClass}
              >
                {nextPage !== null ? nextPage : "done"}
              </Button>
              <Button
                className={totalPageClass}
                onClick={() => {
                  getTickets(lastPage);
                }}
                variant={lastPagClass}
              >
                {lastPage}
              </Button>
              <Button variant="threeDotsBtn"></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
