import React, { useEffect, useState } from "react";
import { Input } from "../components/ui/input";

import { DialogCloseButton } from "../component/Alert";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";
import axios from "axios";

const WorkflowPanel = () => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const [operatorsData, setOperatorsData] = useState([]);
  const [workflowGroupData, setWorkflowGroupsData] = useState([]);
  const [workflowData, setWorkflowData] = useState([]);
  const [requestTypesData, setRequestTypesData] = useState([]);
  const [transitionsData, setTransitionsData] = useState([]);

  const [selectedOperatorsData, setSelectedOperatorsData] = useState([]);
  const [selectedWorkflowGroupData, setSelectedWorkflowGroupsData] = useState(
    []
  );
  const [selectedWorkflowData, setSelectedWorkflowData] = useState([]);
  const [selectedRequestTypesData, setSelectedRequestTypesData] = useState([]);
  const [selectedTransitionsData, setSelectedTransitionsData] = useState([]);

  const [formData, setFormData] = useState({
    label: "",
    value: "",
  });

  const postTransitions = () => {
    axios
      .post(`${URL}/api/workflow_transition_conditions/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          label: formData.label,
          value: formData.value,
          operator: selectedOperatorsData,
          field: selectedRequestTypesData,
          workflow_group: selectedWorkflowGroupData,
          workflow: selectedWorkflowData,
          workflow_transition: selectedTransitionsData,
        },
      })
      .then((res) => {
        setError({
          open: true,
          title: "Successfully accepted!",
          description: res?.data.success_message,
          success: res?.data.success,
        });
        setFormData({
          label: "",
          value: "",
        });
        setSelectedOperatorsData([]);
        setSelectedTransitionsData([]);
        setSelectedRequestTypesData([]);
        setSelectedWorkflowData([]);
        setSelectedWorkflowGroupsData([]);
      })
      .catch((res) => {
        setError({
          open: true,
          title: "Error message",
          description: res?.response?.data?.data?.error,
          success: res?.response?.data?.success,
        });
      });
  };

  const getOperators = () => {
    axios
      .get(`${URL}/api/workflow_transition_conditions/operator/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page_size: 999,
        },
      })
      .then((res) => {
        setOperatorsData(res.data.data);
      })
      .catch(() => {});
  };

  const getWorkflowGroup = () => {
    axios
      .get(`${URL}/api/workflow_group/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page_size: 999,
        },
      })
      .then((res) => {
        // setWorkflowGroupsData(res);
        setWorkflowGroupsData(res.data.data.results);
      })
      .catch(() => {});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getOperators();
    getWorkflowGroup();
  }, []);

  return (
    <>
      {error.open ? (
        <DialogCloseButton setError={setError} error={error} />
      ) : null}
      <div className="panelParent">
        <div className="panelContent">
          <h2>Add workflow transition condition</h2>
          <div className="inputGroup">
            <span>Label:</span>
            <Input
              placeholder=""
              name="label"
              onChange={handleChange}
              value={formData.label}
              // className="w-96 px-2 py-3"
            />
          </div>
          <hr />

          <div className="selectGroup">
            <span>Operator:</span>
            <Select
              name="current_category"
              value={selectedOperatorsData}
              onValueChange={(e) => {
                setSelectedOperatorsData(e);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="overflow-scroll h-64">
                <SelectGroup>
                  {operatorsData.map((item) => {
                    return (
                      <SelectItem key={item.id} value={item.id}>
                        {item.label}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <hr />

          <div className="inputGroup">
            <span>Value:</span>
            <Input
              placeholder=""
              name="value"
              onChange={handleChange}
              value={formData.value}
            />
          </div>
          <hr />

          <div className="selectGroup">
            <span>Workflow group:</span>
            <Select
              name="current_category"
              value={selectedWorkflowGroupData}
              onValueChange={(e) => {
                setSelectedWorkflowGroupsData(e);
                workflowGroupData.filter((item) => {
                  if (item.id === e) {
                    setWorkflowData(item.workflows);
                    setRequestTypesData(item.request_type?.fields);
                  }
                });
                setSelectedTransitionsData([]);
                setSelectedWorkflowData([]);
                setSelectedRequestTypesData([]);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="overflow-scroll h-64">
                <SelectGroup>
                  {workflowGroupData.map((item) => {
                    return (
                      <SelectItem key={item.id} value={item.id}>
                        {item.label}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <hr />

          <div className="selectGroup">
            <span>Workflow:</span>
            <Select
              value={selectedWorkflowData}
              name="current_category"
              onValueChange={(e) => {
                setSelectedWorkflowData(e);
                workflowData.filter((item) => {
                  if (item.id === e) {
                    setTransitionsData(item.transitions);
                  }
                });
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="overflow-scroll h-64">
                <SelectGroup>
                  {workflowData.map((item) => {
                    return (
                      <SelectItem key={item.id} value={item.id}>
                        {item.name}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <hr />

          <div className="selectGroup">
            <span>Transition:</span>
            <Select
              name="current_category"
              value={selectedTransitionsData}
              onValueChange={(e) => {
                setSelectedTransitionsData(e);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="overflow-scroll h-64">
                <SelectGroup>
                  {transitionsData.map((item) => {
                    return (
                      <SelectItem key={item.id} value={item.id}>
                        {item.label}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <hr />

          <div className="selectGroup">
            <span>Field:</span>
            <Select
              name="current_category"
              value={selectedRequestTypesData}
              onValueChange={(e) => {
                setSelectedRequestTypesData(e);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="" />
              </SelectTrigger>
              <SelectContent className="overflow-scroll h-64">
                <SelectGroup>
                  {requestTypesData?.map((item) => {
                    return (
                      <SelectItem key={item.id} value={item.id}>
                        {item.name}
                      </SelectItem>
                    );
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <hr />

          <Button
            onClick={() => {
              postTransitions();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default WorkflowPanel;
