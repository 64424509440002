// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panelParent {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: #264b5d;
}
.panelParent .panelContent {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.panelParent .panelContent h2 {
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
}
.panelParent .panelContent .selectGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.panelParent .panelContent .selectGroup span {
  color: white;
}
.panelParent .panelContent .selectGroup button {
  width: 400px;
}
.panelParent .panelContent .selectGroup button span {
  color: black;
}
.panelParent .panelContent .inputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.panelParent .panelContent .inputGroup span {
  color: white;
}
.panelParent .panelContent .inputGroup input {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/style/pages/workflowPanel.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AAAI;EACE,YAAA;EACA,eAAA;EACA,mBAAA;AAEN;AAAI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEN;AADM;EACE,YAAA;AAGR;AADM;EACE,YAAA;AAGR;AAFQ;EACE,YAAA;AAIV;AAAI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEN;AADM;EACE,YAAA;AAGR;AADM;EACE,YAAA;AAGR","sourcesContent":[".panelParent {\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  background-color: #264b5d;\n  //   align-items: center;\n  .panelContent {\n    width: 600px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    h2 {\n      color: white;\n      font-size: 24px;\n      margin-bottom: 20px;\n    }\n    .selectGroup {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      span {\n        color: white;\n      }\n      button {\n        width: 400px;\n        span {\n          color: black;\n        }\n      }\n    }\n    .inputGroup {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      span {\n        color: white;\n      }\n      input {\n        width: 400px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
