import { useEffect, useState } from "react";
import { Button } from "../components/ui/button";
import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import React from "react";
import Select from "react-select";
import { FilterI, SendIcon } from "../svg";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "../lib/utils";
import { Calendar } from "../components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import Ticket from "../component/Ticket";

const Reports = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  settings,
  dragAndDrop,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");
  const [date, setDate] = useState();

  const navigate = useNavigate();

  const [filterCategoryID, setFilterCategoryID] = useState([]);
  const [filterGroupID, setFilterGroupID] = useState([]);
  const [filterStageID, setFilterStageID] = useState([]);
  const [filterUsersID, setFilterUsersID] = useState([]);
  const [filterCreatedBy, setFilterCreatedBy] = useState([]);
  const [exportURL, setExportURL] = useState("");
  const [assignedOpen, setAssignedOpen] = useState({});

  const [paginition, setPaginition] = useState("false");

  const [dataCategories, setDataCategories] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [users, setUsers] = useState([]);

  const [data, setData] = useState([]);
  const [nextPage, setNextPage] = useState("");
  const [filteredChanger, setFilteredChanger] = useState(false);
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageClass, setCurrentPageClass] = useState("false");
  const [nextClass, setNextClass] = useState("");
  const [prevClass, setPrevClass] = useState("false");
  const [firstPagClass, setFirstPagClass] = useState("blackNumBtn");
  const [lastPagClass, setLastPagClass] = useState("secondary");
  const [allPagClass, setAllPagClass] = useState("secondary");
  const [currentPagVariant, setCurrentPagVariant] = useState("secondary");

  const [totalPageClass, setTotalPageClass] = useState("");

  const [threeDotClassFirst, setThreeDotClassFirst] = useState("false");
  const [threeDotClassSecond, setThreeDotClassSecond] = useState("");

  const [lastPage, setLastPage] = useState("");
  const [filter, setFilter] = useState("false");
  const [notReport, setNotReport] = useState("");

  const [ticketAxiosUsername, setTicketAxiosUsername] = useState("");
  const [refresh, setRefresh] = useState(true);

  const getStages = () => {
    axios(`${URL}/api/stage/list/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 1234567890123456,
      },
    })
      .then((res) => {
        setStageData(res.data.data.results);
      })
      .catch(() => {});
  };

  const getUsers = () => {
    axios(`${URL}/api/auth/user/list/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 1234567890123456,
      },
    })
      .then((res) => {
        setUsers(res.data.data.results);
      })
      .catch(() => {});
  };

  const getCategories = () => {
    axios(`${URL}/api/categories/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 1234567890123456,
        // keyword: "lisenziya",
      },
    })
      .then((res) => {
        setDataCategories(res.data.data.results);
      })
      .catch(() => {});
  };

  const getWorkflowGroupList = () => {
    axios(`${URL}/api/workflow_group/list/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 1234567890123456,
      },
    })
      .then((res) => {
        setGroupData(res.data.data.results);
      })
      .catch(() => {});
  };

  const getFiltered = (props) => {
    // setData([]);
    // setNotReport("Yüklənir...");
    axios
      .get(`${URL}/api/tickets/report/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },

        params: {
          start_date: date?.from?.toISOString(),
          end_date: date?.to?.toISOString(),
          page: props,
          category: filterCategoryID.map((category) => category.value),
          stage: filterStageID.map((stage) => stage.value),
          group: filterGroupID.map((group) => group.value),
          assignee: filterUsersID.map((users) => users.value),
          created_by: filterCreatedBy.map((users) => users.value),
        },
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          for (const key in params) {
            const value = params[key];
            if (Array.isArray(value)) {
              value.forEach((item) => searchParams.append(key, item));
            } else if (value !== undefined && value !== null) {
              searchParams.append(key, value);
            }
          }
          return searchParams.toString();
        },
      })
      .then((res) => {
        setData(res.data.data.results);
        if (res.data.data.next?.split("page=")[1].indexOf("&") === -1) {
          setNextPage(res.data.data.next?.split("page=")[1]);
        } else {
          setNextPage(
            res.data.data.next
              ?.split("page=")[1]
              .substring(0, res.data.data.next?.split("page=")[1].indexOf("&"))
          );
        }
        setCurrentPagVariant("blackNumBtn");
        setAllPagClass("secondary");

        if (
          res.data.data.previous?.split("page=")[1] === undefined ||
          res.data.data.previous
            ?.split("page=")[1]
            .substring(
              0,
              res.data.data.previous?.split("page=")[1].indexOf("&")
            ) === undefined
        ) {
          setPrevPage(2);
        } else {
          if (res.data.data.previous?.split("page=")[1].indexOf("&") === -1) {
            setPrevPage(res.data.data.previous?.split("page=")[1]);
          } else {
            setPrevPage(
              res.data.data.previous
                ?.split("page=")[1]
                .substring(
                  0,
                  res.data.data.previous?.split("page=")[1].indexOf("&")
                )
            );
          }
        }

        setCurrentPage(res.data.data.current_page);
        setLastPage(res.data.data.total_pages);
        res.data.data.count === 0
          ? setNotReport("Report tapılmadı!")
          : setNotReport("");

        res.data.data.count === 0
          ? setPaginition("false")
          : setPaginition("prevNext");

        res.data.data.total_pages === 1
          ? setTotalPageClass("false")
          : setTotalPageClass("");

        res.data.data.current_page === 1
          ? setFirstPagClass("blackNumBtn")
          : setFirstPagClass("secondary");

        res.data.data.current_page === res.data.data.total_pages
          ? setLastPagClass("blackNumBtn")
          : setLastPagClass("secondary");

        res.data.data.current_page > 2
          ? setPrevClass("")
          : setPrevClass("false");

        if (
          res.data.data.current_page === res.data.data.total_pages - 1 ||
          res.data.data.total_pages === res.data.data.current_page
        ) {
          setNextClass("false");
        } else {
          setNextClass("");
        }

        if (res.data.data.current_page + 2 >= res.data.data.total_pages) {
          setThreeDotClassFirst("");
          setThreeDotClassSecond("false");
        } else {
          setThreeDotClassFirst("false");
          setThreeDotClassSecond("");
        }
        if (
          res.data.data.current_page === 1 ||
          res.data.data.current_page === res.data.data.total_pages
        ) {
          setCurrentPageClass("false");
        } else {
          setCurrentPageClass("");
        }
      })
      .catch((res) => {});
  };

  const getAll = () => {
    setData([]);
    setNotReport("Yüklənir...");
    axios
      .get(`${URL}/api/tickets/report/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: date?.from?.toISOString(),
          end_date: date?.to?.toISOString(),
          page_size: 9999999,
          category: filterCategoryID.map((category) => category.value),
          stage: filterStageID.map((stage) => stage.value),
          group: filterGroupID.map((group) => group.value),
          assignee: filterUsersID.map((users) => users.value),
          created_by: filterCreatedBy.map((users) => users.value),
        },
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          for (const key in params) {
            const value = params[key];
            if (Array.isArray(value)) {
              value.forEach((item) => searchParams.append(key, item));
            } else if (value !== undefined && value !== null) {
              searchParams.append(key, value);
            }
          }
          return searchParams.toString();
        },
      })
      .then((res) => {
        setData(res.data.data.results);
        setCurrentPagVariant("secondary");
        setFirstPagClass("secondary");
        setAllPagClass("blackNumBtn");
        setLastPagClass("secondary");
        setNotReport("");
      })
      .catch((res) => {});
  };

  const getReportExport = () => {
    axios
      .get(`${URL}/api/tickets/report/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          as_xls: true,
          start_date: date?.from?.toISOString(),
          end_date: date?.to?.toISOString(),
          category: filterCategoryID.map((category) => category.value),
          stage: filterStageID.map((stage) => stage.value),
          group: filterGroupID.map((group) => group.value),
          assignee: filterUsersID.map((users) => users.value),
          created_by: filterCreatedBy.map((users) => users.value),
        },
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          for (const key in params) {
            const value = params[key];
            if (Array.isArray(value)) {
              value.forEach((item) => searchParams.append(key, item));
            } else if (value !== undefined && value !== null) {
              searchParams.append(key, value);
            }
          }
          return searchParams.toString();
        },
      })
      .then((res) => {
        setExportURL(res.data.data.url);
      })
      .catch(() => {});
  };

  const getTicketByID = (props) => {
    axios(`${URL}/api/tickets/${props}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setAssignedOpen({
          ticket: res?.data.data,
          isAssignedMe: true,
        });
      })
      .catch(() => {});
  };

  const getMyUserReports = () => {
    axios
      .get(`${URL}/api/auth/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTicketAxiosUsername(res.data.data.id);
      })
      .catch(() => {});
  };

  const sideBarCheck = () => {
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data.some((item) => item.label === "REPORT_ADVANCED")) {
        } else {
          navigate("/*");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    sideBarCheck();
    getUsers();
    getStages();
    getCategories();
    getWorkflowGroupList();
    getMyUserReports();
  }, []);

  useEffect(() => {
    filteredChanger === false ? getFiltered() : getAll();
    getReportExport();
  }, [
    filterCategoryID,
    filterStageID,
    filterGroupID,
    filterUsersID,
    filterCreatedBy,
    date,
    refresh,
  ]);

  return (
    <>
      {assignedOpen.isAssignedMe
        ? [assignedOpen.ticket].map((item) => {
            const datee = new Date(item.created_at).toLocaleDateString();
            const dateee = new Date(item.created_at);
            const hours = dateee.getHours();
            const minutes = dateee.getMinutes();
            const seconds = dateee.getSeconds();
            const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
              minutes < 10 ? "0" : ""
            }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

            const dateCompleted = new Date(
              item?.completed_at
            ).toLocaleDateString();
            const dateCompleted2 = new Date(item?.completed_at);
            const hours2 = dateCompleted2.getHours();
            const minutes2 = dateCompleted2.getMinutes();
            const seconds2 = dateCompleted2.getSeconds();
            const formattedTime2 = `${hours2 < 10 ? "0" : ""}${hours2}:${
              minutes2 < 10 ? "0" : ""
            }${minutes2}:${seconds2 < 10 ? "0" : ""}${seconds2}`;
            return (
              <Ticket
                assignedMe
                setAssignedOpen={setAssignedOpen}
                ticketLabel={item?.current_workflow?.name}
                key={item.id}
                id={item.id}
                customFields={item?.fields}
                requestTypeFields={item?.current_category?.request_type?.fields}
                ticketNum={"Tiket# " + item.id}
                customerName={item.title}
                comment={item.description}
                setStateRefresh={setRefresh}
                completedAt={item.completed_at != null ? "false" : ""}
                ifAssigneMePost={
                  item?.assignee?.username === localStorage.getItem("username")
                    ? ""
                    : "false"
                }
                stateRefresh={refresh}
                background={
                  item?.assignee === null
                    ? "ticket22"
                    : item.completed_at != null
                    ? "ticketEnd"
                    : "ticketInProgress"
                }
                state={item.current_stage}
                createdBy={
                  item?.created_by?.full_name.length === 0
                    ? item.created_by.username
                    : item?.created_by?.full_name
                }
                ownerGroup={item.owner_group?.name}
                usernameID={ticketAxiosUsername}
                ticketAssigneID={item?.assignee?.id}
                category={item.current_category.name}
                icraci={item.assignee ? "icraçı" : ""}
                assigned={
                  item.assignee
                    ? item?.assignee?.full_name.length === 0
                      ? item?.assignee?.username
                      : item?.assignee?.full_name
                    : "İcraçı təyin edilməyib"
                }
                date={datee}
                time={formattedTime}
                dateCompleted={
                  item.completed_at === null
                    ? ""
                    : `Tamamlandı:${dateCompleted}`
                }
                timeCompleted={item.completed_at === null ? "" : formattedTime2}
                phoneNum={item.phone_number}
                cashier_id={item?.cashier_id}
                voen={item?.voen}
              />
            );
          })
        : null}
      <div>
        <SideBar
          settings={settings}
          createdByMeTickets={createdByMeTickets}
          propsReports={"reportsVisit"}
          faq={faq}
          reportPerCategory={reportPerCategory}
          reportPerUser={reportPerUser}
          reportAdvanced={reportAdvanced}
          allTicAcc={allTicAcc}
          myGroupTicAcc={myGroupTicAcc}
          dashboardAcc={dashboardAcc}
          myTicAcc={myTicAcc}
          setSideBarReport={setSideBarReport}
          sideBarReport={sideBarReport}
          myGroupTicketCount={myGroupTicketCount}
          workflowReport={workflowReport}
          userWorkflowReport={userWorkflowReport}
          dragAndDrop={dragAndDrop}
        />
        <TopBar
          setUser={setUser}
          username={propsUsername}
          nameForDisplay={nameForDisplay}
          unreadNot={unreadNot}
          notificationRefresh={notificationRefresh}
          setNotificationRefresh={setNotificationRefresh}
          notificationData={notificationData}
          setNotificationCount={setNotificationCount}
        />
        <div className="reportsTT">
          <div className="reports">
            <div className="reportsTitle">
              <div className="reportsTitleTT">
                <h2>Ətraflı Report</h2>
              </div>

              <div className="reportsTitleR">
                <Button
                  onClick={() => {
                    filter === "false"
                      ? setFilter("filter")
                      : setFilter("false");
                  }}
                  className="reportsFilterBtn"
                  variant="secondary"
                >
                  <FilterI />
                </Button>
                <div className="dateFilterFirst">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        id="date"
                        variant={"calendar"}
                        className={cn(
                          "w-[240px] justify-start text-left font-normal",
                          !date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                          date.to ? (
                            <>
                              {format(date.from, "LLL dd, y")} -{" "}
                              {format(date.to, "LLL dd, y")}
                            </>
                          ) : (
                            format(date.from, "LLL dd, y")
                          )
                        ) : (
                          <span className="calendarText">Tarix</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <div className="dateFilterFirst">
                  <Link target="_blank" rel="noreferrer" to={exportURL}>
                    <Button className={cn("w-[105px]")} variant="mybtn2">
                      <SendIcon />
                      <span className="newTicketText">Export</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="dateFilterSecond">
              <Link target="_blank" rel="noreferrer" to={exportURL}>
                <Button className="w-full ml-0" variant="mybtn2">
                  <SendIcon />
                  <span className="newTicketText">Export</span>
                </Button>
              </Link>
            </div>
            <div className="dateFilterSecond">
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant={"calendar"}
                    className={cn(
                      "w-full justify-start text-left font-normal",
                      !date && "text-muted-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                      date.to ? (
                        <>
                          {format(date.from, "LLL dd, y")} -{" "}
                          {format(date.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(date.from, "LLL dd, y")
                      )
                    ) : (
                      <span className="calendarText">Tarix</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className={filter}>
              {/* <h6>Kategoriya üzrə:</h6> */}
              <div className="filterSelect">
                <Select
                  options={dataCategories.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                  onChange={(e) => {
                    setFilterCategoryID(e);
                  }}
                  isMulti
                  placeholder="Kateqoriya"
                />
              </div>
              {/* <h6>Qruplar üzrə:</h6> */}
              {/* <div className="filterSelect">
              <Select
                options={groupData.map((item) => {
                  return { value: item.id, label: item.label };
                })}
                onChange={(e) => {
                  setFilterGroupID(e);
                }}
                isMulti
                placeholder="Qruplar"
              />
            </div> */}
              {/* <h6>Cari status üzrə:</h6> */}
              <div className="filterSelect">
                <Select
                  options={stageData.map((item) => {
                    return { value: item.id, label: item.label };
                  })}
                  onChange={(e) => {
                    setFilterStageID(e);
                  }}
                  isMulti
                  placeholder="Cari statuslar"
                />
              </div>
              {/* <h6>Icrada olan əməkdaş:</h6> */}
              <div className="filterSelect">
                <Select
                  options={users.map((item) => {
                    return { value: item.id, label: item.username };
                  })}
                  onChange={(e) => {
                    setFilterUsersID(e);
                  }}
                  isMulti
                  placeholder="Icraçılar"
                />
              </div>
              {/* <h6>Tiket açan əməkdaş:</h6> */}
              <div className="filterSelect">
                <Select
                  options={users.map((item) => {
                    return { value: item.id, label: item.username };
                  })}
                  onChange={(e) => {
                    setFilterCreatedBy(e);
                  }}
                  isMulti
                  placeholder="İstifadəçilər"
                />
              </div>
            </div>

            <div className="advancedReportParent">
              <table id="advancedReport">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Tiketi açan əməkdaş</th>
                    <th>Qrup</th>
                    <th>İD (kassa nömrəsi)</th>
                    <th>VOEN</th>
                    <th>Əlaqə nömrəsi</th>
                    <th>Cari status</th>
                    <th>İcrada olan əməkdaş</th>
                    <th>Tiketin açılma tarixi</th>
                    <th>Tiketin yekun tarixi</th>
                    <th>Mövzu</th>
                    <th>Tiketin icra vaxtı</th>
                    <th>Description</th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((item) => {
                    const timestamp1 = new Date(item.completed_at);
                    const timestamp2 = new Date(item.created_at);

                    const timeDifferenceInMillis = Math.abs(
                      timestamp1 - timestamp2
                    );
                    const hours = Math.floor(
                      timeDifferenceInMillis / (1000 * 60 * 60)
                    );
                    const minutes = Math.floor(
                      (timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    const seconds = Math.floor(
                      (timeDifferenceInMillis % (1000 * 60)) / 1000
                    );

                    const formattedTimeDifference = `${hours
                      .toString()
                      .padStart(2, "0")}:${minutes
                      .toString()
                      .padStart(2, "0")}:${seconds
                      .toString()
                      .padStart(2, "0")}`;
                    return (
                      <tr key={item.id}>
                        <td
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#0000EE",
                          }}
                          onClick={() => {
                            getTicketByID(item.id);
                          }}
                        >
                          {item.id}
                        </td>
                        <td>
                          {item?.created_by?.full_name.length === 0
                            ? item.created_by.username
                            : item?.created_by?.full_name}
                        </td>
                        <td>{item?.owner_group?.name}</td>
                        <td>
                          {item?.cashier_id?.length === 0 ||
                          item?.cashier_id === null
                            ? "Kassa nömrəsi qeyd olunmayıb"
                            : item?.cashier_id}
                        </td>
                        <td>
                          {item?.voen?.length === 0 || item?.voen === null
                            ? "VOEN qeyd olunmayıb"
                            : item?.voen}
                        </td>
                        <td>
                          {item?.phone_number?.length === 0 ||
                          item?.phone_number === null
                            ? "Telefon nömrəsi qeyd olunmayıb"
                            : item?.phone_number}
                        </td>
                        <td>{item?.current_stage?.label}</td>
                        <td>
                          {item?.assignee?.full_name.length === 0
                            ? item?.assignee?.username
                            : item?.assignee?.full_name}
                          {item?.assignee?.full_name === undefined
                            ? "Icraçı təyin olunmayıb"
                            : ""}
                        </td>
                        <td>
                          {`${item?.created_at?.split("T")[0]} ${
                            item?.created_at?.split("T")[1].split(".")[0]
                          }`}
                        </td>
                        <td>
                          {item?.completed_at !== null
                            ? `${item?.completed_at?.split("T")[0]} ${
                                item?.completed_at?.split("T")[1].split(".")[0]
                              }`
                            : "Tiket yekunlaşmayıb"}
                        </td>
                        {/* <td>{item?.current_workflow?.name}</td> */}
                        <td>{item?.current_category?.name}</td>
                        <td>
                          {item.completed_at === null
                            ? "Tiket yekunlaşmayıb"
                            : formattedTimeDifference}
                        </td>
                        <td>
                          {item?.description?.length === 0 ||
                          item?.description?.length === undefined
                            ? "Qeyd yoxdur"
                            : `${item?.description?.slice(0, 12)}...`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <h2 className="text-xl text-rose-700">{notReport}</h2>
            <div className={paginition}>
              <Button
                onClick={() => {
                  setFilteredChanger(false);
                  getFiltered(1);
                }}
                variant={firstPagClass}
              >
                1
              </Button>
              <Button className={threeDotClassFirst} variant="threeDotsBtn">
                ...
              </Button>

              <Button
                onClick={() => {
                  setFilteredChanger(false);
                  getFiltered(prevPage);
                }}
                variant="secondary"
                className={prevClass}
              >
                {prevPage !== null ? prevPage : "done"}
              </Button>
              <Button className={currentPageClass} variant={currentPagVariant}>
                {currentPage}
              </Button>
              <Button
                onClick={() => {
                  setFilteredChanger(false);
                  getFiltered(nextPage);
                }}
                variant="secondary"
                className={nextClass}
              >
                {nextPage !== null ? nextPage : "done"}
              </Button>
              <Button className={threeDotClassSecond} variant="threeDotsBtn">
                ...
              </Button>
              <Button
                className={totalPageClass}
                onClick={() => {
                  setFilteredChanger(false);
                  getFiltered(lastPage);
                }}
                variant={lastPagClass}
              >
                {lastPage}
              </Button>

              <Button
                className={totalPageClass}
                onClick={() => {
                  setFilteredChanger(true);
                  getAll();
                }}
                variant={allPagClass}
              >
                Hamısı
              </Button>
              <Button
                className="paginitionForDistance"
                variant="threeDotsBtn"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
