import React, { useState } from "react";

const BarChart = ({ data, firstBarName, secondBarName }) => {
  const [hoveredBar, setHoveredBar] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  // Find the maximum values for normalizing both value1 and value2
  const maxValue1 = Math.max(...data.map((item) => item.value1));
  const maxValue2 = Math.max(...data.map((item) => item.value2));

  const handleMouseEnter = (index, event) => {
    const { clientX, clientY } = event;
    setHoveredBar(index);
    setTooltipPosition({ x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setHoveredBar(null);
  };

  return (
    <div style={styles.chartContainer}>
      {data.map((item, index) => (
        <div
          key={index}
          style={styles.barContainer}
          onMouseEnter={(event) => handleMouseEnter(index, event)}
          onMouseLeave={handleMouseLeave}
        >
          <div style={styles.barsContainer}>
            <div
              style={{
                ...styles.bar,
                height: `${(item.value1 / maxValue1) * 100}%`,
                backgroundColor: item.color || "steelblue",
                marginRight: "2px", // Space between bars
              }}
            >
              <span style={styles.barLabel}>{item.value1}</span>
            </div>
            <div
              style={{
                ...styles.bar,
                height: `${(item.value2 / maxValue2) * 100}%`,
                backgroundColor: item.color2 || "tomato",
              }}
            >
              <span style={styles.barLabel}>{item.value2}</span>
            </div>
          </div>
          <span style={styles.label}>{item.label}</span>
          {hoveredBar === index && (
            <div
              style={{
                ...styles.tooltip,
                top: tooltipPosition.y - 10,
                left: tooltipPosition.x + 10,
              }}
            >
              <p>{item.label}</p>
              <p>
                {firstBarName}: {item.value1}
              </p>
              <p>
                {secondBarName}: {item.value2}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const styles = {
  chartContainer: {
    display: "flex",
    alignItems: "flex-end",
    height: "350px",
    width: "100%",
    overflow: "auto",
    border: "1px solid #E4E4E7",
    padding: "10px",
    backgroundColor: "#fafafa",
    position: "relative",
    scrollbarWidth: "none",
    marginBottom: "30px",
  },
  barContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 5px",
    height: "95%",
    position: "relative",
  },
  barsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "100%",
  },
  bar: {
    width: "20px",
    position: "relative",
  },
  barLabel: {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "12px",
    color: "#000",
  },
  label: {
    marginTop: "10px",
    fontSize: "12px",
    fontFamily: "Inter",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    fontWeight: "400",
  },
  tooltip: {
    position: "fixed", // Change to fixed to position it relative to the viewport
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "3px",
    pointerEvents: "none",
    zIndex: 1,
    whiteSpace: "nowrap",
    transform: "translate(-50%, -100%)",
  },
};

export default BarChart;
