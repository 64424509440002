import React, { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";

import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogTrigger,
} from "../components/ui/dialog";

import axios from "axios";
import { Pin } from "lucide-react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Label } from "../components/ui/label";
import { DialogCloseButton } from "../component/Alert";
import {
  ArrowDown,
  ArrowUp,
  CategoriesArrow,
  CircleForCategorie,
} from "../svg";
const ClientSubmitForm = ({ setUser }) => {
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const [refresh, setRefresh] = useState(true);
  const [newTicketCategories, setNewTicketCategories] = useState();
  const [dataCategories, setDataCategories] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [groupsData, setGroupsData] = useState([]);

  const [requestTypeData, setRequestTypeData] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [selectedKeywordData, setSelectedKeywordData] =
    useState("clientSubmitForm");

  const [ticketCategoriesPlaceholder, setTicketCategoriesPlaceholder] =
    useState("Kategoriya seçin");

  const [categorieArrows, setCategorieArrows] = useState("false");

  const [mappedState, setMappedState] = useState([]);
  const [error, setError] = useState({
    open: false,
    title: null,
    description: null,
    success: null,
  });

  const [categoriesSearch, setCategoriesSearch] = useState({
    search: "",
  });

  const [initialFormData, setInitialFormData] = useState(
    requestTypeData.map((field) => ({
      field_definition: field.name,
      value: "",
    }))
  );

  const [ticketData, setTicketData] = useState(initialFormData);

  const dates = new Date();
  let days = dates.getDate();
  let months = dates.getMonth() + 1;
  let years = dates.getFullYear();
  let currentDate = `${days}/${months}/${years}`;

  const handleClick = () => {
    setNewTicketCategories();
  };

  const handleClear = () => {
    setTicketData(initialFormData);
  };

  const ticketPost = () => {
    const filteredData = ticketData.filter((field) => field.value !== "");
    axios
      .post(
        `${URL}/api/categories/${newTicketCategories}/submit_form/`,
        {
          custom_fields: filteredData.length > 0 ? filteredData : [],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleClear();
        setRequestTypeData([]);
        setNewTicketCategories();
        setError({
          open: true,
          title: "Successfully accepted!",
          description: res?.data.success_message,
          success: res?.data.success,
        });
        setRefresh(!refresh);
        setTicketCategoriesPlaceholder("Kategoriya seçin");
        handleClick();
        setCategoriesSearch({
          search: "",
        });
      })
      .catch((res) => {
        if (res?.response?.status === 400) {
          setError({
            open: true,
            title: "Error message",
            description: res?.response?.data?.data?.error,
            success: res?.response?.data?.success,
          });
        }
      });
  };

  const handleChange = (e, field_definition) => {
    const { value } = e.target;
    setTicketData((prevData) =>
      prevData.map((field) =>
        field.field_definition === field_definition
          ? { ...field, value }
          : field
      )
    );
  };

  const handleChangeCategorySearch = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setCategoriesSearch((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const getKeywords = () => {
    axios
      .get(`${URL}/api/keyword/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setKeywordsData(res.data.data.results);
      })
      .catch(() => {});
  };

  const getGroups = () => {
    axios
      .get(`${URL}/api/auth/group/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page_size: 999,
        },
      })
      .then((res) => {
        setGroupsData(res.data.data.results);
      })
      .catch(() => {});
  };

  const getCategories = (props) => {
    axios(`${URL}/api/categories/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page_size: 9999999,
        search: categoriesSearch.search,
        keyword: selectedKeywordData,
        user_group: props,
        ordering: "name",
      },
    })
      .then((res) => {
        setDataCategories(res.data.data.results);
      })
      .catch(() => {});
  };

  useEffect(() => {
    const mapped = requestTypeData.map((item) => ({
      [item.filter_with]: "",
    }));
    setMappedState(mapped);
  }, [requestTypeData]);

  const handleInputChange = (fieldName, value) => {
    const updatedState = mappedState.map((item) => {
      if (fieldName in item) {
        return {
          [fieldName]: value,
        };
      }
      return item;
    });
    setMappedState(updatedState);
  };

  useEffect(() => {
    getGroups();
    getKeywords();
  }, []);

  useEffect(() => {
    getCategories();
  }, [categoriesSearch, selectedKeywordData]);

  return (
    <>
      <div>
        {error.open ? (
          <DialogCloseButton setError={setError} error={error} />
        ) : null}
        <div className="dashboard" style={{ width: "100%", margin: "0px" }}>
          <div
            className="dashboardContent"
            style={{
              justifyContent: "center",
              height: "93vh",
            }}
          >
            <div
              className="dashboardRight"
              style={{
                width: "420px",
                height: "fit-content",
              }}
            >
              <div className="newTicketTitleDashboard">
                <div className="newTicketR">
                  <Pin />
                  <span>Submit Form</span>
                </div>
                <span>{currentDate}</span>
              </div>
              <hr className="newTicketHR" />

              <div className="flex gap-4 flex-col">
                <div className="newTicketSelectDash">
                  <div className="mb-1">
                    <Label>
                      Müraciətin növü{" "}
                      <span className="text-amber-900 text-sm font-medium leading-5">
                        *
                      </span>
                    </Label>
                  </div>
                  <Dialog defaultOpen={popoverOpen}>
                    <DialogTrigger asChild>
                      <div className="categoriesOpener">
                        <span>{ticketCategoriesPlaceholder}</span>
                        <CategoriesArrow />
                      </div>
                    </DialogTrigger>
                    <DialogContent className="categorieContent">
                      <div className="categorieTitle">
                        <span>Mövzu seçin</span>
                        <div className="categorieTT">
                          <Input
                            placeholder="#2 Token"
                            onChange={handleChangeCategorySearch}
                            id="name"
                            name="search"
                            className="w-96 px-2 py-3"
                          />
                          <Select
                            name="current_category"
                            onValueChange={(e) => {
                              getCategories(e);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="" />
                            </SelectTrigger>
                            <SelectContent className="overflow-scroll h-64">
                              <SelectGroup>
                                <SelectItem>Bütün qruplar</SelectItem>
                                {groupsData.map((item) => {
                                  return (
                                    <SelectItem key={item.id} value={item.id}>
                                      {item.name}
                                    </SelectItem>
                                  );
                                })}
                              </SelectGroup>
                            </SelectContent>
                          </Select>

                          <Select
                            name="current_category"
                            onValueChange={(e) => {
                              setSelectedKeywordData(e);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Keyword seçin" />
                            </SelectTrigger>
                            <SelectContent className="overflow-auto h-64">
                              <SelectGroup>
                                {keywordsData.map((item) => {
                                  return (
                                    <SelectItem key={item.id} value={item.name}>
                                      {item.name}
                                    </SelectItem>
                                  );
                                })}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      <div className="categoriesArea">
                        {dataCategories.map((item) => {
                          return (
                            <>
                              <DialogClose className="firstCategorieCard">
                                <div
                                  key={item.id}
                                  className="categorieCard"
                                  style={{
                                    background: `${item.color}`,
                                  }}
                                  onClick={() => {
                                    setCategoriesSearch({
                                      search: "",
                                    });
                                    setNewTicketCategories(item.id);
                                    setTicketCategoriesPlaceholder(item.name);
                                    setPopoverOpen(false);
                                    if (
                                      item?.request_type?.fields === undefined
                                    ) {
                                      setTicketData([]);
                                      setInitialFormData([]);
                                      setRequestTypeData([]);
                                    } else {
                                      setRequestTypeData(
                                        item.request_type.fields
                                      );
                                      setTicketData(
                                        item.request_type.fields.map(
                                          (field) => ({
                                            field_definition: field.name,
                                            value: "",
                                          })
                                        )
                                      );
                                      setInitialFormData(
                                        item.request_type.fields.map(
                                          (field) => ({
                                            field_definition: field.name,
                                            value: "",
                                          })
                                        )
                                      );
                                    }
                                  }}
                                >
                                  <span>{item.name}</span>
                                  <p>{item.description}</p>
                                  <hr />
                                  <div className="cardWorkflowArea">
                                    {item.workflows?.map((item) => {
                                      return (
                                        <div
                                          key={item.id}
                                          className="cardWorkflow"
                                        >
                                          {item.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </DialogClose>
                              <div className="secondCategorieCard">
                                <div
                                  key={item.id}
                                  className="categorieCard"
                                  style={{
                                    background: `${item.color}`,
                                  }}
                                >
                                  <div className="secondCategorieCardTitle">
                                    <DialogClose
                                      onClick={() => {
                                        setCategoriesSearch({
                                          search: "",
                                        });
                                        setNewTicketCategories(item.id);
                                        setTicketCategoriesPlaceholder(
                                          item.name
                                        );
                                        setPopoverOpen(false);
                                        if (
                                          item?.request_type?.fields ===
                                          undefined
                                        ) {
                                          setTicketData([]);
                                          setInitialFormData([]);
                                          setRequestTypeData([]);
                                        } else {
                                          setRequestTypeData(
                                            item.request_type.fields
                                          );
                                          setTicketData(
                                            item.request_type.fields.map(
                                              (field) => ({
                                                field_definition: field.name,
                                                value: "",
                                              })
                                            )
                                          );
                                          setInitialFormData(
                                            item.request_type.fields.map(
                                              (field) => ({
                                                field_definition: field.name,
                                                value: "",
                                              })
                                            )
                                          );
                                        }
                                      }}
                                      className="flex items-center gap-3"
                                    >
                                      <CircleForCategorie />
                                      <span>{item.name}</span>
                                    </DialogClose>
                                    <span
                                      onClick={() => {
                                        categorieArrows === item.id
                                          ? setCategorieArrows("")
                                          : setCategorieArrows(item.id);
                                      }}
                                    >
                                      {categorieArrows !== item.id ? (
                                        <ArrowDown className="cursor-pointer" />
                                      ) : (
                                        <ArrowUp className="cursor-pointer" />
                                      )}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display:
                                        categorieArrows !== item.id
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <p>{item.description}</p>
                                    <hr className="secondCategorieCardHR" />
                                    <div className="cardWorkflowArea">
                                      {item.workflows?.map((item) => {
                                        return (
                                          <div
                                            key={item.id}
                                            className="cardWorkflow"
                                          >
                                            {item.name}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>

                {requestTypeData.map((item, index) => {
                  return (
                    <div className="newTicketInpBoxDash">
                      <div className="mb-1">
                        <Label>
                          {item?.display_name}
                          <span
                            className={item.required === false ? "false" : ""}
                          >
                            <span className="text-amber-900 text-sm font-medium leading-5">
                              *
                            </span>
                          </span>
                        </Label>
                      </div>
                      <Input
                        className="w-full"
                        onChange={(e) => {
                          if (item.searchable === true) {
                            handleInputChange(item.filter_with, e.target.value);
                          }
                          handleChange(e, item.name);
                        }}
                        // name={item?.name}
                        value={ticketData[index].value}
                        placeholder="000 000"
                        type={
                          item?.backend_field_type === "IntegerField"
                            ? "number"
                            : "text"
                        }
                      />
                    </div>
                  );
                })}
              </div>
              <div className="flex items-center space-x-2 mt-3"></div>
              <Button
                onClick={() => {
                  ticketPost();
                }}
                type="submit"
                variant="mybtn"
                width="400px"
              >
                <span className="newTicketText">Göndər</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientSubmitForm;
