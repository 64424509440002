import TopBar from "../component/TopBar";
import SideBar from "../component/SideBar";
import FaqCard from "../component/FaqCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FAQ = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  settings,
  myGroupTicketCount,
}) => {
  const URL = process.env.REACT_APP_ENVIRONMENT;
  const token = localStorage.getItem("access");

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const getFaqData = () => {
    axios
      .get(`${URL}/api/faq/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data.data.results);
      })
      .catch(() => {});
  };

  const sideBarCheck = () => {
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data.some((item) => item.label === "FAQ")) {
        } else {
          navigate("/*");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    sideBarCheck();
    getFaqData();
  }, []);

  return (
    <div>
      <SideBar
        settings={settings}
        createdByMeTickets={createdByMeTickets}
        propsFaq={"faqVisit"}
        faq={faq}
        reportPerCategory={reportPerCategory}
        reportPerUser={reportPerUser}
        reportAdvanced={reportAdvanced}
        allTicAcc={allTicAcc}
        myGroupTicAcc={myGroupTicAcc}
        dashboardAcc={dashboardAcc}
        myTicAcc={myTicAcc}
        setSideBarReport={setSideBarReport}
        sideBarReport={sideBarReport}
        myGroupTicketCount={myGroupTicketCount}
        workflowReport={workflowReport}
        userWorkflowReport={userWorkflowReport}
        dragAndDrop={dragAndDrop}
      />
      <TopBar
        setUser={setUser}
        username={propsUsername}
        nameForDisplay={nameForDisplay}
        unreadNot={unreadNot}
        notificationRefresh={notificationRefresh}
        setNotificationRefresh={setNotificationRefresh}
        notificationData={notificationData}
        setNotificationCount={setNotificationCount}
      />
      <div className="faqTT">
        <div className="faq">
          <div className="faqTitle">
            <h2>FAQ</h2>
            <div className="searchInp">
              <input
                type="text"
                className="searchInp"
                name="search"
                placeholder="Saul axtar"
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          </div>
          {data
            .filter((item) => {
              if (item === "") {
                return item;
              } else if (
                item.question.toLowerCase().includes(query.toLowerCase())
              ) {
                return item;
              } else {
                return null;
              }
            })
            .map((item) => (
              <FaqCard
                key={item.id}
                num={item.id}
                title={item.question}
                content={item.answer}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
