import React from "react";

const TaskHistoryChanger = (props) => {
  return (
    <div>
      <div className="history">
        <div className="historyIMG">
          <img
            className="historyIMGTT"
            src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
            alt=""
          />
          <span>{props.name}</span>
        </div>
        <span>{props.date}</span>
      </div>
      <div className="historyText">
        <span>{props.status}</span>
      </div>
    </div>
  );
};

export default TaskHistoryChanger;
