import SideBar from "../component/SideBar";
import TopBar from "../component/TopBar";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Tabs, TabsList, TabsTrigger } from "../components/ui/tabs";
import { useEffect, useState } from "react";
import axios from "axios";
import { Label } from "../components/ui/label";
import { Switch } from "../components/ui/switch";
import { Checkbox } from "../components/ui/checkbox";
import { useNavigate } from "react-router-dom";

const Settings = ({
  setUser,
  propsUsername,
  nameForDisplay,
  faq,
  reportPerCategory,
  reportPerUser,
  reportAdvanced,
  allTicAcc,
  myGroupTicAcc,
  dashboardAcc,
  myTicAcc,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  sideBarReport,
  setSideBarReport,
  createdByMeTickets,
  workflowReport,
  userWorkflowReport,
  dragAndDrop,
  settings,
  myGroupTicketCount,
}) => {
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const navigate = useNavigate();

  const [loginData, setLoginData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [errormessage, setErrormessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [tablistValue, setTablistValue] = useState("security");
  const [notificationIcon, setNotificationIcon] = useState(
    localStorage.getItem("notifications")
  );

  const changePass = () => {
    setErrormessage("");
    if (
      loginData.new_password.length >= 8 &&
      loginData.confirm_password.length >= 8
    ) {
      axios
        .post(`${URL}/api/auth/me/change_password/`, loginData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((res) => {
          setErrormessage("Şifrə yanlış qeyd olunub");
        })
        .then((res) => {
          if (res?.status) {
            setSuccessMessage("Şifrə uğurla dəyişdirildi!");
            setErrormessage("");
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            localStorage.removeItem("username");
            setUser(false);
            navigate("/");
          }
        });
    } else {
      setErrormessage("Şifrə 8 simvoldan artıq olmalıdır!");
    }
  };

  const something = (event) => {
    if (event.keyCode === 13) {
      changePass();
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setLoginData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sideBarCheck = () => {
    axios
      .get(`${URL}/api/component/list/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data.some((item) => item.label === "SETTINGS")) {
        } else {
          navigate("/*");
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    sideBarCheck();
  }, []);

  return (
    <>
      <div className="home">
        <SideBar
          propsSettings={"settingsVisit"}
          settings={settings}
          myTicAcc={myTicAcc}
          createdByMeTickets={createdByMeTickets}
          faq={faq}
          reportPerCategory={reportPerCategory}
          reportPerUser={reportPerUser}
          reportAdvanced={reportAdvanced}
          allTicAcc={allTicAcc}
          myGroupTicAcc={myGroupTicAcc}
          dashboardAcc={dashboardAcc}
          setSideBarReport={setSideBarReport}
          sideBarReport={sideBarReport}
          myGroupTicketCount={myGroupTicketCount}
          workflowReport={workflowReport}
          userWorkflowReport={userWorkflowReport}
          dragAndDrop={dragAndDrop}
        />
        <TopBar
          setUser={setUser}
          username={propsUsername}
          nameForDisplay={nameForDisplay}
          unreadNot={unreadNot}
          notificationRefresh={notificationRefresh}
          setNotificationRefresh={setNotificationRefresh}
          notificationData={notificationData}
          setNotificationCount={setNotificationCount}
          notificationIcon={notificationIcon}
        />
        <div className="tickets">
          <div className="ticketsContent">
            <div className="ticketsTitleTT">
              <div className="ticketsTitle">
                <div className="">
                  <div className=""></div>
                  <Tabs
                    onValueChange={(value) => {
                      setTablistValue(value);
                    }}
                    defaultValue="security"
                    className="h-7 mb-6"
                  >
                    <TabsList>
                      {/* <TabsTrigger
                        className="w-[175.5px] h-7"
                        value="accountSetting"
                      >
                        Hesab tənzimləmələri
                      </TabsTrigger> */}
                      <TabsTrigger className="w-[175.5px] h-7" value="security">
                        Şifrə və Təhlükəsizlik
                      </TabsTrigger>
                      <TabsTrigger
                        className="w-[96px] h-7"
                        value="notifications"
                      >
                        Bildirişlər
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="ticketsArea">
              {tablistValue === "security" ? (
                <div className="changepass">
                  <span>Hesaba giriş şifrəsi</span>
                  <hr />
                  <div className="">
                    <div className="flex gap-4 mb-4">
                      <Label htmlFor="name" className="text-left p-1 w-[90px]">
                        Hazırki şifrə
                      </Label>
                      <Input
                        id="old"
                        onChange={handleChange}
                        onKeyDown={(e) => something(e)}
                        name="old_password"
                        type="password"
                        placeholder="Hazırki şifrə"
                        className="w-[320px]"
                      />
                    </div>
                    <div className="flex gap-4 mb-4">
                      <Label htmlFor="name" className="text-left p-1 w-[90px]">
                        Yeni şifrə
                      </Label>
                      <Input
                        id="new"
                        onChange={handleChange}
                        onKeyDown={(e) => something(e)}
                        name="new_password"
                        type="password"
                        placeholder="Yeni şifrə"
                        className="w-[320px]"
                      />
                    </div>
                    <div className="flex gap-4 mb-4">
                      <Label htmlFor="name" className="text-left p-1 w-[90px]">
                        Təkrar
                      </Label>
                      <Input
                        id="confirm"
                        onChange={handleChange}
                        onKeyDown={(e) => something(e)}
                        name="confirm_password"
                        type="password"
                        placeholder="Şifrəni təsdiqlə"
                        className="w-[320px]"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <span className="text-red-500 font-inter font-normal text-sm mt-0 mb-2">
                      {errormessage}
                    </span>
                    <span className="text-green-500 font-inter font-normal text-sm mt-0 mb-2">
                      {successMessage}
                    </span>
                  </div>

                  <Button
                    onClick={() => {
                      changePass();
                    }}
                    type="submit"
                  >
                    Yadda saxla
                  </Button>
                </div>
              ) : (
                <div>
                  <div className="notificationSwitch">
                    <Switch
                      defaultChecked={localStorage.getItem("notifications")}
                      onCheckedChange={(value) => {
                        setNotificationRefresh(
                          value === true
                            ? !notificationRefresh
                            : notificationRefresh
                        );
                        value === true
                          ? setNotificationIcon("notifications")
                          : setNotificationIcon("false");
                        localStorage.setItem(
                          "notifications",
                          value === true ? true : ""
                        );
                      }}
                    />
                    <Label>Bildirişlər</Label>
                  </div>
                  <div className="notificationSwitch">
                    <Checkbox defaultChecked={true} disabled />
                    <Label>Qrupumun bildirişləri</Label>
                  </div>
                  <div className="notificationSwitch">
                    <Checkbox defaultChecked={true} disabled />
                    <Label>Mənə təyin olunanlar</Label>
                  </div>
                  <div className="notificationSwitch">
                    <Checkbox defaultChecked={true} disabled />
                    <Label>Kommetlər</Label>
                  </div>
                  <div className="notificationSwitch">
                    <Checkbox defaultChecked={true} disabled />
                    <Label>Yaratdığım tiketlər üzrə</Label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
