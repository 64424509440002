// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phoneNumInp div {
  display: flex;
}
.phoneNumInp div .inputSearch {
  width: 100%;
  display: flex;
  align-items: center;
}
.phoneNumInp div .inputSearch .dashboardSearch {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 44px;
  width: 44px;
  background-color: #f4f4f5;
  cursor: pointer;
}
.phoneNumInp div .inputSearch input {
  width: 85%;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #e7e7e7;
  outline: #e7e7e7;
}
.phoneNumInp div .inputSearch input::-webkit-outer-spin-button,
.phoneNumInp div .inputSearch input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/style/component/phoneNumberInput.scss"],"names":[],"mappings":"AAUE;EACE,aAAA;AATJ;AAUI;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AARN;AASM;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;AAPR;AASM;EACE,UAAA;EAEA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,gBAAA;AARR;AAUM;;EAEE,wBAAA;EACA,SAAA;AARR;;AAaA;;EAEE,wBAAA;EACA,SAAA;AAVF","sourcesContent":[".phoneNumInp {\n  // select {\n  //   outline: none;\n  //   width: 80px;\n  //   border-top-left-radius: 4px;\n  //   border-bottom-left-radius: 4px;\n  //   border: 1px solid #e7e7e7;\n  //   padding: 8px 0 8px 12px;\n  //   border-right: none;\n  // }\n  div {\n    display: flex;\n    .inputSearch {\n      width: 100%;\n      display: flex;\n      align-items: center;\n      .dashboardSearch {\n        margin-left: 10px;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border-radius: 6px;\n        height: 44px;\n        width: 44px;\n        background-color: #f4f4f5;\n        cursor: pointer;\n      }\n      input {\n        width: 85%;\n        // height: 50px;\n        padding: 8px 12px;\n        align-items: center;\n        gap: 10px;\n        border-radius: 4px;\n        border: 1px solid #e7e7e7;\n        outline: #e7e7e7;\n      }\n      input::-webkit-outer-spin-button,\n      input::-webkit-inner-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n      }\n    }\n  }\n}\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
