// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFoundTT {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
.notFoundTT .notFound {
  width: 650px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 20px;
}
.notFoundTT .notFound h2 {
  font-weight: 600;
  font-size: 38px;
}
.notFoundTT .notFound p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}
.notFoundTT .notFound span {
  padding: 15px 20px;
  border: 2px solid black;
  font-weight: 600;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/style/pages/notFound.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;EACA,aAAA;EACA,2CAAA;EACA,kBAAA;AAEJ;AADI;EACE,gBAAA;EACA,eAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,kBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;AAGN","sourcesContent":[".notFoundTT {\n  display: flex;\n  height: 80vh;\n  justify-content: center;\n  align-items: center;\n  .notFound {\n    width: 650px;\n    height: 350px;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n    padding: 10px 20px;\n    h2 {\n      font-weight: 600;\n      font-size: 38px;\n    }\n    p {\n      font-size: 18px;\n      font-weight: 500;\n      margin-bottom: 30px;\n    }\n    span {\n      padding: 15px 20px;\n      border: 2px solid black;\n      font-weight: 600;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
