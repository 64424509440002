import React from "react";
import { DangerI, NetworkI } from "../svg";

const AnnouncmentCard = ({ title, level, content }) => {
  const data = level;

  return (
    <div className={data}>
      {level === "danger" ? (
        <DangerI className="announcmentIcon" />
      ) : (
        <NetworkI className="announcmentIcon" />
      )}
      <div className="announcmentR">
        <p>{title}</p>
        <span>{content}</span>
      </div>
    </div>
  );
};

export default AnnouncmentCard;
