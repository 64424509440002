import {
  ArrowDown,
  ArrowUp,
  BellWithDotI,
  Chime,
  LogOutI,
  NotificationDot,
} from "../svg";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import axios from "axios";

const TopBar = ({
  setUser,
  username,
  unreadNot,
  notificationRefresh,
  setNotificationRefresh,
  notificationData,
  setNotificationCount,
  nameForDisplay,
  notificationIcon,
}) => {
  const token = localStorage.getItem("access");
  const URL = process.env.REACT_APP_ENVIRONMENT;

  const navigate = useNavigate();
  const [logOutBtn, setLogOutBtn] = useState("true");
  const [logout, setLogOut] = useState("false");
  const [moreNotfBtn, setMoreNotfBtn] = useState("");
  const [userProfileClass, setUserProfileClass] = useState("userProfileTT");

  // const [hideDrop, setHideDrop] = useState("false");
  // const [lang, setLang] = useState("AZ");
  // const [langBtn, setLangBtn] = useState("false");
  // const langFunc = () => {
  //   lang === "AZ" ? setLang("RU") : setLang("AZ");
  //   setHideDrop("false");
  //   setLangBtn("false");
  // };
  // const langBtnChange = () => {
  //   if (langBtn === "true") {
  //     setLangBtn("false");
  //     setHideDrop("false");
  //   } else {
  //     setLangBtn("true");
  //     setHideDrop("showDrop");
  //   }
  // };

  const logOutFunc = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("username");
    setUser(false);
    navigate("/");
  };

  const arrowDownUp = () => {
    if (logOutBtn === "true") {
      setLogOutBtn("false");
      setLogOut("profileDrop");
      setUserProfileClass("userProfileTTActive");
    } else {
      setLogOutBtn("true");
      setLogOut("false");
      setUserProfileClass("userProfileTT");
    }
  };

  const markRead = (props) => {
    axios
      .post(
        `${URL}/api/notification/${props}/mark_as_read/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setNotificationRefresh(!notificationRefresh);
      })
      .catch(() => {});
  };

  const markAllRead = () => {
    axios
      .post(
        `${URL}/api/notification/mark_all_as_read/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setNotificationRefresh(!notificationRefresh);
      })
      .catch(() => {});
  };

  return (
    <>
      <div className="topBar">
        <img
          onClick={() => navigate("/dashboard")}
          className="logoImg"
          src={require("../images/sideBarLogo.png")}
          alt=""
        />
        <div className="moonIcon">{/* <MoonI /> */}</div>
        <img
          className="topBarResponsiveIMG"
          src={require("../images/sideBarLogo.png")}
          alt=""
        />

        {/* <div onClick={langBtnChange} className="languageChanger">
          <span>{lang}</span>
          <div>{langBtn === "false" ? <ArrowDown /> : <ArrowUp />}</div>
          <p className={hideDrop} onClick={langFunc}>
            <span>{lang === "AZ" ? "RU" : "AZ"}</span>
          </p>
        </div> */}
        <div
          className={
            notificationIcon === "undefined"
              ? notificationIcon
              : localStorage.getItem("notifications") === "true"
              ? "notification"
              : "disabledNotification"
          }
        >
          <DropdownMenu>
            <DropdownMenuTrigger
              disabled={
                notificationIcon === "undefined"
                  ? notificationIcon
                  : localStorage.getItem("notifications") === "true"
                  ? false
                  : true
              }
            >
              {unreadNot === true &&
              localStorage.getItem("notifications") === "true" ? (
                <BellWithDotI className="BellWithDotI" />
              ) : (
                <div className="BellWithOutDotI">
                  <Chime />
                </div>
              )}
              {/* <Chime className="cursor-pointer" /> */}
            </DropdownMenuTrigger>
            <DropdownMenuContent className=" mt-6 w-88 h=[500px]">
              <DropdownMenuGroup>
                <div className="notificationArea">
                  <p
                    onClick={markAllRead}
                    className="text-center cursor-pointer mb-1"
                  >
                    Hamısını oxu
                  </p>
                  <hr />
                  {notificationData.map((item) => {
                    return (
                      <div key={item.id}>
                        <div
                          onClick={() =>
                            item.is_read === false ? markRead(item.id) : ""
                          }
                          className={
                            item.is_read === false
                              ? "notificationCardUnread"
                              : "notificationCard"
                          }
                        >
                          <div className="notifiDate">
                            <span>{item?.created_at?.split("T")[0]} </span>
                            <span>
                              {item?.created_at
                                ?.split("T")[1]
                                .split(".")[0]
                                .slice(0, -3)}
                            </span>
                          </div>
                          <div>
                            <span className="notifiTitle">{item.title}</span>
                            <p className="notifiDesc">{item.message}</p>
                          </div>
                          <span
                            className={item.is_read === false ? "" : "false"}
                          >
                            <NotificationDot />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  <div className={moreNotfBtn}>
                    <p
                      onClick={() => {
                        setMoreNotfBtn("false");
                        setNotificationCount(99999999);
                        setNotificationRefresh(!notificationRefresh);
                      }}
                      className="text-center	pt-4 pb-3 cursor-pointer text-blue-600"
                    >
                      Daha çox
                    </p>
                  </div>
                </div>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="userProfile" onClick={arrowDownUp}>
          <div className={userProfileClass}>
            <img
              src={require("../images/WhatsApp Image 2024-03-06 at 17.49.14_55293353.jpg")}
              alt=""
            />
            <span className="topBarUsername">
              {nameForDisplay?.first_name?.length +
                nameForDisplay?.last_name?.length ===
              0
                ? username
                : ""}
              {nameForDisplay?.first_name === undefined
                ? ""
                : nameForDisplay?.first_name}{" "}
              {nameForDisplay?.last_name === undefined
                ? ""
                : nameForDisplay?.last_name}
            </span>
            <div className="cursor-pointer">
              {logOutBtn === "false" ? <ArrowUp /> : <ArrowDown />}
            </div>
          </div>

          <div className={logout}>
            <div onClick={logOutFunc}>
              <h6>Çıxış</h6>
              <LogOutI />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
