import { Dialog, DialogClose, DialogContent } from "../components/ui/dialog";
import { Error, MiniClose, Success } from "../svg";
export function DialogCloseButton({ setError, error }) {
  return (
    <Dialog
      open={error.open}
      onOpenChange={() =>
        setError({ open: false, title: null, description: null, success: null })
      }
    >
      <DialogContent className="alert">
        <div className="alertDIV">
          <DialogClose
            style={{
              backgroundColor: "#f4f4f5",
            }}
            className="alertCloseButton"
          >
            <MiniClose />
          </DialogClose>
          {error.success === false ? <Error /> : <Success />}
          <h2>{error.title}</h2>
          <p>{error.description}</p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
